import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Chart from "../../asset/conceptChart.svg";

function ConceptChart() {
  return (
    <Grid container spacing={3}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 180, sm: 320, md: 460, lg: 620 },
            minHeight: { xs: 170, sm: 310, md: 450, lg: 610 },
            maxWidth: { xs: 360, sm: 670, md: 920, lg: 1460 },
            minWidth: { xs: 350, sm: 660, md: 910, lg: 1350 },
            // border: "2px solid black",
          }}>
          <img
            src={Chart}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default ConceptChart;
