import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import CustomDutyCpst from "../../components/customsDutyManagement/cpstCustomDutyManagement";
import CustomDutyMain from "../../components/customsDutyManagement/main";
import AssetManagementSystem from "../../components/projects/assetManagementSystem";
import CustomDutyManagement from "../../components/projects/customDutyManagement";
import GainConfidence from "../../components/projects/gainConfidence";
import Maps from "../../components/projects/maps";
import MedicalDiagnosisManagement from "../../components/projects/medicalDiagnosisManagement";
import OurProducts from "../../components/projects/ourProducts";
import RequestProposal from "../../components/projects/requestProposal";
import RetailErpSolution from "../../components/projects/retailErpSolution";
import ShippingExecutionManagement from "../../components/projects/shippingExecutionManagement";
import ShippingRequirementsManagement from "../../components/projects/shippingRequirementsManagement";
import SupplyChainDefectManagement from "../../components/projects/supplyChainDefectManagement";
import TruckTrackingTool from "../../components/projects/truckTrackingTool";
import WeStartProject from "../../components/projects/weStartProject";
import WholesaleDistributionManagement from "../../components/projects/wholesaleDistributionManagement";
import YellowCardTemp from "../../components/yellowCardTemp";
import ResponsiveAppBar from "../../layout/appBar";
import LogoBlack from "../../asset/logoBlack.png";
import AreYouWilling from "../../components/projects/areYouWilling";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";
import { useNavigate } from "react-router-dom";
// import { TabTitle } from "../../utils/commonFunction";
import OracleDtoT from "../../components/services/oracleDtoT";
import OracleDb from "../../components/services/oracleDb";
import { Helmet } from "react-helmet";
import { contactUsWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";

function Projects() {
  const dispatch = useDispatch();

  // TabTitle('VEETEE - Projects')
  let navigate = useNavigate();
  const handleGetInTouch = () => {
    // console.log("Dalapathy");
    navigate("/contactUs");
    // window.scroll(900,900)
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
    dispatch(contactUsWindowScrollAction(650));

  };
  return (
    <Grid container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VEETEE - Projects</title>
        <link rel="canonical" href="http://localhost:3000/projects" />
        <meta name='title' content={"title"} />
        <meta name='description' content={"description"} />
      </Helmet>
      <ResponsiveAppBar
        navBarBgColor={"common.yellow"}
        ButtonBgcolor={"transparent"}
        logo={LogoBlack}
        ButtonBorder={"black"}
        bText={"common.black"}
        text="common.black"
        handleGetInTouch={handleGetInTouch}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{ backgroundColor: "common.yellow" }}
        maxHeight="40vh">
        <OurProducts />
      </Grid>
      <Grid
        className="appMargin"
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        bgcolor="common.paper1">
        <AreYouWilling />
      </Grid>
      <Grid
        className="appMargin"
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        bgcolor="common.paper1">
        <GainConfidence />
      </Grid>
      <Grid className="appMargin" item lg={12} md={12} sm={12} xs={12}>
        <WeStartProject />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <CustomDutyManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingExecutionManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <SupplyChainDefectManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingRequirementsManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <MedicalDiagnosisManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <WholesaleDistributionManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <OracleDtoT />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <OracleDb />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <TruckTrackingTool />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <AssetManagementSystem />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <RetailErpSolution />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Maps />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{
          backgroundColor: "common.lightOrange",
        }}>
        <RequestProposal />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Projects;
