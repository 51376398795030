import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

export default function Intro() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={3} pb={"20px"}>
            <Grid item lg={6} md={6} sm={12} xs={12} display="flex">
              <Typography
                variant="t40_700"
                sx={{
                  color: "common.white",
                }}>
                {"Oracle Discoverer To Tableau Migration"}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center">
              <Box>
                <Typography
                  variant="t20_400"
                  sx={{
                    color: "common.white",
                    display: "flex",
                    justifyContent: "center",
                    pb: "20px",
                  }}>
                  {
                    "Oracle Discoverer is a Business Intelligence product that was used for demand planning by a multinational consumer goods corporation."
                  }
                </Typography>
                <Typography
                  variant="t20_400"
                  sx={{
                    color: "common.white",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  {
                    "The business was caught off guard for licensing when the product reached End Of Life Support. Hence all complex reports and dashboards were moved to Tableau within 3 weeks."
                  }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
        item
        lg={9}
        md={10}
        sm={10}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 160, sm: 260, md: 420, lg: 480 },
            minHeight: { xs: 150, sm: 250, md: 410, lg: 470 },
            maxWidth: { xs: 360, sm: 660, md: 1020, lg: 1320 },
            minWidth: { xs: 350, sm: 650, md: 1010, lg: 1310 },
            // border: "2px solid black",
          }}>
          {" "}
          <img
            src={image}
            alt=""
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          />
          {type === "right" ? (
            <img
              src={image2}
              alt=""
              style={{
                position: "absolute",
                height: "30%",
                width: "10%",
                top: "85%",
                left: "85%",
              }}
            />
          ) : (
            <img
              src={image2}
              alt=""
              style={{
                position: "absolute",
                height: "30%",
                width: "15%",
                top: "85%",
                left: "7%",
              }}
            />
          )}
        </Box>
      </Grid> */}
      </Grid>
    </Container>
  );
}
