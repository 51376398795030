import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import StrengthCards from "./strengthCards";
import Attitude from "../../asset/attitude.svg";
import Attention from "../../asset/attention.svg";
import Nimbleness from "../../asset/nimbleness.svg";
import Thought from "../../asset/thought.svg";

function Strength() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={5}
        display="flex"
        flexDirection="row"
        justifyContent="center">
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center">
          <Typography variant="t52_700">{"Our Strength"}</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={5}
            display="flex"
            flexDirection="row"
            justifyContent="center">
            <Grid item lg={6} md={6} sm={6} xs={12} display="flex" justifyContent="flex-end">
              <StrengthCards
                img={Attitude}
                title={"Attitude"}
                content={`Our "Customer first" attitude and always striving to wow the Customer.`}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} display="flex">
              <StrengthCards
                img={Attention}
                title={"Attention"}
                content={
                  "Our consistent and attentive service that is rarely delivered by large organizations."
                }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} display="flex" justifyContent="flex-end">
              <StrengthCards
                img={Nimbleness}
                title={"Nimbleness"}
                content={
                  "We are very nimble in the services we provide without all of the slow internal bureaucracy."
                }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} display="flex">
              <StrengthCards
                img={Thought}
                title={"Thought Leadership"}
                content={
                  "We only hire seasoned Consultants, who can provide thought leadership that will save your company."
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Strength;
