import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConceptChart from "../../components/contactUs/conceptChart";
import GetInTouch from "../../components/contactUs/form";
import Map from "../../components/contactUs/map";
import ResponsiveAppBar from "../../layout/appBar";
import LogoBlack from "../../asset/logoBlack.png";
import QuickAccess from "../../layout/quickAccess";
import MapTabs from "../../components/contactUs/mapTab";
import "../../index.css";
import OpenStreetMap from "../../components/contactUs/osMap";
import { useNavigate } from "react-router-dom";
// import { TabTitle } from "../../utils/commonFunction";
import "./map.css";
import { Helmet } from "react-helmet";
import { contactUsWindowScrollAction } from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";

function ContactUs() {
  const dispatch = useDispatch();

  const {
    AdminReducer: { contact_window },
  } = useSelector((state) => state);

  // TabTitle("VEETEE - Contact Us");
  const [coordinates, setCoordinates] = useState([
    {
      id: 1,
      latitude: 12.990955047466512,
      longitude: 80.21827047238786,
      country: "India",
      company_name: "VeeTee Technologies",
      company_address:
        "65, 1st Floor, Sankaran Avenue, Radhakrishnan Street, Velachery 100ft Road, Chennai - 600 042. TN, India.",
      phone_number: "+91-44-4211 7914",
      mail: "veetee@vttech.in",
    },
    {
      id: 2,
      latitude: 51.5072,
      longitude: 0.1276,
      country: "UK",
      company_name: "VeeTee Technologies",
      company_address: "UK",
      phone_number: "+91-44-4211 7914",
      mail: "veetee@vttech.in",
    },
    {
      id: 3,
      latitude: 36.1716,
      longitude: 155.5828,
      country: "US",
      company_name: "VeeTee Technologies",
      company_address: "US",
      phone_number: "+91-44-4211 7914",
      mail: "veetee@vttech.in",
    },
  ]);

  let navigate = useNavigate();
  const handleGetInTouch = () => {
    // console.log("Dalapathy");
    navigate("/contactUs");
    // window.scroll(900,900 , {behavior: 'smooth',})
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
    dispatch(contactUsWindowScrollAction(650));

  };

  useEffect(() => {

    window.scrollTo({
      top: contact_window,
      behavior: "smooth",
    });
  }, []);


  console.log('contact_window', contact_window);

  return (
    <Grid container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VEETEE - Contact Us</title>
        <link rel="canonical" href="http://localhost:3000/contactUs" />
        <meta name='title' content={"title"} />
        <meta name='description' content={"description"} />
      </Helmet>
      <ResponsiveAppBar
        navBarBgColor={"common.green"}
        ButtonBgcolor={"common.orange"}
        logo={LogoBlack}
        ButtonBorder={"transparent"}
        bText={"common.white"}
        text={"common.black"}
        hoverColor={"common.white"}
        hBorderColor={"#FFFFFF"}
        handleGetInTouch={handleGetInTouch}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ backgroundColor: "common.green" }}
        className="appMargin">
        <ConceptChart />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <GetInTouch />
      </Grid>
      {/* <div className="">
        <div className=""> */}
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
      // style={{ position: "relative" }}
      >
        <Grid style={{}}>
          {/* <OpenStreetMap
            coordinates={coordinates}
            setCoordinates={setCoordinates}
          /> */}
        </Grid>

        {/* <Grid
          style={{
            // border: "2px solid red",
            position: "absolute",
            top: "40%",
            right: "3%",
          }}> */}
        <MapTabs />
        {/* </Grid> */}
      </Grid>

      {/* </div> */}
      <div className="tab"></div>
      {/* </div> */}
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default ContactUs;
