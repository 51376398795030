import { Grid, Typography } from "@mui/material";
import React from "react";
import GridTempTextImage1 from "./gridTemp1";
import Laptop from "../../asset/lap5.svg"


export default function MedicalDiagnosisManagement() {
  const title = "Medical Diagnosis Management";
  const subTitle = "Mobile Application Development";
  const benefit = "Improved Patient / Doctor Experience,Call Cost Savings, FTE Resource Reduction";
  const launch = "12 Weeks";
  const project = "Completed";
  const button = "View Details";

  const content = [
    {
      content:
        "Medical Diagnosis Management application is used by a chain of medical diagnostic service centres with more than 5000 centres.",
    },
    {
      content:
        "This hybrid mobile application helps patients to book tests, patients and doctors to view/download medical test reports.",
    },
    {
      content: "React",
    },
  ];
  return (
    <GridTempTextImage1
      title={title}
      content={content}
      benefit={benefit}
      launch={launch}
      project={project}
      subTitle={subTitle}
      button={button}
      image={Laptop}
    />
  );
}
