import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

export default function Intro() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={3} pb={"20px"}>
            <Grid item lg={6} md={6} sm={12} xs={12} display="flex">
              <Typography
                variant="t40_700"
                sx={{
                  color: "common.white",
                }}>
                {"Oracle Database Upgrade"}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center">
              <Box>
                <Typography
                  variant="t20_400"
                  sx={{
                    color: "common.white",
                    display: "flex",
                    justifyContent: "center",
                    pb: "20px",
                  }}>
                  {
                    "Oracle Database used by Finance department of a multinational consumer goods corporation was on version 11g."
                  }
                </Typography>
                <Typography
                  variant="t20_400"
                  sx={{
                    color: "common.white",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  {
                    "The department faced performance and scalability issues with unsupported 11g version. It also had to bear the entire server cost, as all other departments had stopped using the server. Hence all finance data were migrated to Oracle 12c server."
                  }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
        item
        lg={9}
        md={10}
        sm={10}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 160, sm: 260, md: 420, lg: 480 },
            minHeight: { xs: 150, sm: 250, md: 410, lg: 470 },
            maxWidth: { xs: 360, sm: 660, md: 1020, lg: 1320 },
            minWidth: { xs: 350, sm: 650, md: 1010, lg: 1310 },
            // border: "2px solid black",
          }}>
          {" "}
          <img
            src={image}
            alt=""
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          />
          {type === "right" ? (
            <img
              src={image2}
              alt=""
              style={{
                position: "absolute",
                height: "30%",
                width: "10%",
                top: "85%",
                left: "85%",
              }}
            />
          ) : (
            <img
              src={image2}
              alt=""
              style={{
                position: "absolute",
                height: "30%",
                width: "15%",
                top: "85%",
                left: "7%",
              }}
            />
          )}
        </Box>
      </Grid> */}
      </Grid>
    </Container>
  );
}
