import React from "react";
import OracleDtoTa from "../../asset/oracleDtoT.svg";
import GridTempTextImage1 from "../projects/gridTemp1";

function OracleDtoT() {
  const title = "Oracle Discoverer To Tableau Migration";
  const subTitle = "Data Analyticst";
  const benefit = "Oracle License Cost Saving, Improved Data Visualization";
  const launch = "3 Weeks";
  const project = "Maintaining for past 1 year";
  const button = "View Details";
  const pageUrl = "/projects/oracleDiscovererToTableauMigration";

  const content = [
    {
      content:
        "Oracle Discoverer is a Business Intelligence product that was used for demand planning by a multinational consumer goods corporation.",
    },
    {
      content:
        "The business was caught off guard for licensing when the product reached End Of Life Support. Hence all complex reports and dashboards were moved to Tableau within 3 weeks.",
    },
    {
      content: "Oracle Discoverer, Tableau",
    },
  ];
  return (
    <GridTempTextImage1
      title={title}
      content={content}
      benefit={benefit}
      launch={launch}
      project={project}
      subTitle={subTitle}
      button={button}
      image={OracleDtoTa}
      pageUrl={pageUrl}
      type="view"
    />

    // <Grid
    //   container
    //   spacing={7}
    //   display="flex"
    //   alignItems="center"
    //   justifyContent="center">
    //   <Grid item lg={6} md={6} sm={12} xs={12}>
    //     <Grid
    //       container
    //       spacing={3}
    //       display="flex"
    //       alignItems="center"
    //       justifyContent="center">
    //       <Grid item lg={12} md={12} sm={12} xs={12}>
    //         <Typography variant="t52_400_L56">
    //           {""}
    //         </Typography>
    //       </Grid>
    //       <Grid item lg={12} md={12} sm={12} xs={12}>
    //         <Typography variant="t14_400" color={"#00BA90"}>
    //           {""}
    //         </Typography>
    //       </Grid>
    //       <Grid item lg={12} md={12} sm={12} xs={12}>
    //         <Typography variant="t18_400">
    //           {
    //             ""
    //           }
    //         </Typography>
    //       </Grid>
    //       <Grid item lg={12} md={12} sm={12} xs={12}>
    //         <Typography variant="t18_400">
    //           {
    //             ""
    //           }
    //         </Typography>
    //       </Grid>
    //       <Grid item lg={12} md={12} sm={12} xs={12}>
    //         <Typography variant="t18_400">
    //           {""}
    //         </Typography>
    //       </Grid>
    //       <Grid item lg={12} md={12} sm={12} xs={12}>
    //         <Grid container display="flex" flexDirection="row">
    //           <Grid item lg={2.5} md={1} sm={2.5} xs={1}>
    //             <Typography variant="t18_400_L34" color={"common.yellow"}>
    //               Benefits:
    //             </Typography>
    //           </Grid>
    //           <Grid item lg={9.5} md={11} sm={9.5} xs={12}>
    //             <Typography variant="t18_400_L34">
    //               {""}
    //             </Typography>
    //           </Grid>
    //         </Grid>
    //         <Grid container display="flex">
    //           <Grid item lg={2.5} md={1} sm={2.5} xs={12}>
    //             <Typography color={"common.yellow"} variant="t18_400_L34">
    //               MVP Launch:
    //             </Typography>
    //           </Grid>
    //           <Grid item lg={9.5} md={11} sm={2} xs={12}>
    //             <Typography variant="t18_400_L34">{""}</Typography>
    //           </Grid>
    //         </Grid>
    //         <Grid container display="flex" flexDirection="row">
    //           <Grid item lg={2.5} md={2.5} sm={2.5} xs={12}>
    //             <Typography color={"common.yellow"} variant="t18_400_L34">
    //               Project Status:
    //             </Typography>
    //           </Grid>
    //           <Grid item lg={9.5} md={5} sm={5} xs={12}>
    //             <Typography variant="t18_400_L34">
    //               {""}
    //             </Typography>
    //           </Grid>
    //         </Grid>
    //         <Grid item lg={3} md={3} sm={3} xs={12} pt="20px">
    //           {" "}
    //           <Button
    //             sx={{
    //               backgroundColor: "common.yellow",
    //               border: `1.5px solid whitesmoke`,
    //               color: "common.white",
    //               width: "100%",
    //               height: "100%",
    //               ":hover": {
    //                 border: `1.5px solid #FD7E3B`,
    //                 color: "common.yellow",
    //               },
    //             }}>
    //             <Typography variant="t18_400_L34">{"View Details"}</Typography>
    //           </Button>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   </Grid>

    //   <Grid
    //     item
    //     lg={6}
    //     md={6}
    //     sm={12}
    //     xs={12}
    //     display="flex"
    //     alignItems="center"
    //     justifyContent="center">
    //     <Box
    //       display="flex"
    //       alignItems="center"
    //       justifyContent="center"
    //       sx={{
    //         position: "relative",
    //         maxHeight: { xs: 90, sm: 360, md: 360, lg: 360 },
    //         minHeight: { xs: 80, sm: 350, md: 350, lg: 350 },
    //         maxWidth: { xs: 260, sm: 560, md: 420, lg: 620 },
    //         minWidth: { xs: 250, sm: 550, md: 410, lg: 610 },
    //         // border: "2px solid black",,
    //       }}>
    //       <img
    //         src={OracleDtoTa}
    //         alt="NoImage"
    //         style={{ height: "70%", width: "70%" }}
    //       />
    //     </Box>
    //   </Grid>
    // </Grid>
  );
}

export default OracleDtoT;
