import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Eclipse from "../../asset/eclipse.png";
import Man from "../../asset/man.png";
import HalfCircle from "../../asset/halfcircle.png";
import Note from "../../asset/note.png";

export default function OurProducts() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={5}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid container spacing={5} color="common.white">
            <Grid item lg={12}>
              <Typography
                variant="t60_700"
                sx={
                  {
                    // color: "common.white",
                    // display: "flex",
                    // justifyContent: "center",
                    // p: "20px 0px 20px 0px",
                  }
                }>
                Key Projects
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography
                // sx={{
                //   fontSize: "20px",
                //   fontWeight: "400",
                //   color: "white",
                //   fontFamily: "Helvetica Neue",
                //   lineHeight: "23px",
                // }}
                variant="t23_400">
                Tempus urna et pharetra pharetra massa. Consequat id porta nibh
                venenatis cras sed. Ridiculus mus mauris vitae ultricies leo
                integer malesuada nunc.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center">
          <Box
            sx={{
              position: "relative",
              maxHeight: { xs: 260, sm: 460, md: 490 },
              minHeight: { xs: 250, sm: 450, md: 480 },
              maxWidth: { xs: 260, sm: 460, md: 490 },
              minWidth: { xs: 250, sm: 450, md: 480 },
              // // border: "2px solid black",,
            }}>
            <img
              src={Eclipse}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "95%",
                height: "100%",
                top: "0%",
                bottom: "0%",
                left: "5%",
              }}
            />
            <img
              src={Man}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "115%",
                height: "115%",
                right: "13%",
                bottom: "0%",
              }}
            />
            <img
              src={HalfCircle}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "17%",
                height: "35%",
                left: "15%",
                bottom: "70%",
              }}
            />
            <img
              src={Note}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "50%",
                height: "35%",
                top: "74%",
                bottom: "0%",
                left: "54%",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
