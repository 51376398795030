import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import SkillsImg_3 from "../../asset/skillsImg_3.svg";

function SoftwareTesting() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={7}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "relative",
              maxHeight: { xs: 90, sm: 360, md: 360, lg: 360 },
              minHeight: { xs: 80, sm: 350, md: 350, lg: 350 },
              maxWidth: { xs: 260, sm: 560, md: 420, lg: 620 },
              minWidth: { xs: 250, sm: 550, md: 410, lg: 610 },
              // border: "2px solid black",,
            }}>
            <img
              src={SkillsImg_3}
              alt={"NoImage"}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={{
                  fontFamily: "Helvetica Neue",
                  fontSize: "52px",
                  fontWeight: "700",
                  lineHeight: "56px",
                  textAlign: "left",
                }}>
                {"Software Testing"}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container display="flex" flexDirection="row" pb="20px">
                <Grid item lg={1} md={1} sm={1} xs={1} color="common.blue">
                  <EastIcon />
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <Typography variant="t18_400">
                    {
                      "Preventing you from costly defects with our Shift Left Testing approach."
                    }
                  </Typography>
                </Grid>
              </Grid>
              <Grid container display="flex" flexDirection="row">
                <Grid item lg={1} md={1} sm={1} xs={1} color="common.blue">
                  <EastIcon />
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <Typography variant="t18_400">
                    {
                      "Reducing regression test time with our Test Automation Tool that is proven on variety of applications hosted locally and on cloud platforms."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SoftwareTesting;
