import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="150.799"
      height="70.8"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 9610.98 2800.66">
      <defs>
        <clipPath id="id0">
          <path d="M68.68 89.03H9574.85V2805.07H68.68z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#id0)">
        <image
          width="9506.16"
          height="2716.03"
          x="68.68"
          y="89.04"
          xlinkHref="new logo cdr_Images\\new logo cdr_ImgID1.png"></image>
      </g>
    </svg>
  );
}

export default Icon;
