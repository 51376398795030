import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";

function GridTempTextImage(props) {
  const { title, image, content, type } = props;
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={7}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid
            container
            spacing={3}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="t52_700"
                sx={{ height: "100%", width: "100%" }}>
                {title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {content.map((m) => (
                <>
                  <Grid container display="flex" flexDirection="row" pb="20px">
                    <Grid
                      item
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      color={
                        type === "skills" ? "common.blue" : "common.orange"
                      }>
                      <EastIcon />
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                      <Typography variant="t18_400">{m.content}</Typography>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: "relative",
                  maxHeight: { xs: 90, sm: 360, md: 360, lg: 360 },
                  minHeight: { xs: 80, sm: 350, md: 350, lg: 350 },
                  maxWidth: { xs: 260, sm: 560, md: 460, lg: 620 },
                  minWidth: { xs: 250, sm: 550, md: 450, lg: 610 },
                  // border: "2px solid black",,
                }}>
                <img
                  src={image}
                  alt={""}
                  style={{
                    height: "90%",
                    width: "90%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default GridTempTextImage;
