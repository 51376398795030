import { Container, Grid, Typography } from "@mui/material";
import React from "react";

function Intro(props) {
  const { type } = props;
  return (
    <Container maxWidth="xl">
      <Grid container spacing={7} display="flex" flexDirection="row-reverse">
        {type === "mobile" && (
          <>
            <Grid item lg={2} md={1} sm={1} xs={1}></Grid>
            <Grid item lg={2} md={1} sm={1} xs={1}></Grid>
            <Grid item lg={2} md={1} sm={1} xs={1}></Grid>
            <Grid item lg={2} md={1} sm={1} xs={1}></Grid>
            <Grid item lg={2} md={1} sm={1} xs={1}></Grid>
            <Grid item lg={2} md={1} sm={1} xs={1}></Grid>
          </>
        )}
        <Grid item lg={6} md={12} sm={1} xs={1}></Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Typography variant="t52_700">
            {
              "Every Service We Provide Is Our Customer And Your Customer Centric"
            }
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Intro;
