import { Grid } from "@material-ui/core";
import { Box, Container, Typography } from "@mui/material";
import React from "react";
import TimerIcon from "@mui/icons-material/Timer";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import timeIcon from "../../asset/time.svg";
import developerModeIcon from "../../asset/developerModeIcon.svg";
import cloudDownloadIcon from "../../asset/cloudDownloadIcon.svg";
import newspaperIcon from "../../asset/newspaperIcon.svg";

export default function WeStartProject() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={7}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={10} md={11} sm={11} xs={12}>
              <Typography variant="t52_700_L70px">
                We start projects by understanding Your Business & Your Customer
                Journeys!
              </Typography>
            </Grid>
            <Grid item lg={10} md={11} sm={11} xs={12}>
              <Typography variant="t18_400">
                Our passionate and professional offshore team of Web Developers,
                Mobile Developers, Software Testers, Data Analysts & IT
                Operation experts have the project track records shown below.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={7}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Grid container display={"flex"} flexDirection={"column"}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      position: "relative",
                      maxHeight: { xs: 100, sm: 100, md: 100, lg: 100 },
                      minHeight: { xs: 95, sm: 95, md: 95, lg: 95 },
                      maxWidth: { xs: 100, sm: 100, md: 100, lg: 100 },
                      minWidth: { xs: 95, sm: 95, md: 95, lg: 95 },
                      // // border: "2px solid black",,
                    }}>
                    <img
                      src={timeIcon}
                      alt=""
                      sx={{ height: "100%", width: "100%" }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography color={"common.yellow"} variant="t64_400">
                    200K
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="t16_400">Working Hours</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Grid container display={"flex"} flexDirection={"column"}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      position: "relative",
                      maxHeight: { xs: 100, sm: 100, md: 100, lg: 100 },
                      minHeight: { xs: 95, sm: 95, md: 95, lg: 95 },
                      maxWidth: { xs: 100, sm: 100, md: 100, lg: 100 },
                      minWidth: { xs: 95, sm: 95, md: 95, lg: 95 },
                      // // border: "2px solid black",,
                    }}>
                    <img
                      src={developerModeIcon}
                      alt=""
                      sx={{ height: "100%", width: "100%" }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography color={"common.yellow"} variant="t64_400">
                    100+
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="t16_400">Features developed</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Grid container display={"flex"} flexDirection={"column"}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      position: "relative",
                      maxHeight: { xs: 100, sm: 100, md: 100, lg: 100 },
                      minHeight: { xs: 95, sm: 95, md: 95, lg: 95 },
                      maxWidth: { xs: 100, sm: 100, md: 100, lg: 100 },
                      minWidth: { xs: 95, sm: 95, md: 95, lg: 95 },
                      // // border: "2px solid black",,
                    }}>
                    <img
                      src={cloudDownloadIcon}
                      alt=""
                      sx={{ height: "100%", width: "100%" }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography color={"common.yellow"} variant="t64_400">
                    50+
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="t16_400">
                    Technologies applied
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Grid container display={"flex"} flexDirection={"column"}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      position: "relative",
                      maxHeight: { xs: 100, sm: 100, md: 100, lg: 100 },
                      minHeight: { xs: 95, sm: 95, md: 95, lg: 95 },
                      maxWidth: { xs: 100, sm: 100, md: 100, lg: 100 },
                      minWidth: { xs: 95, sm: 95, md: 95, lg: 95 },
                      // // border: "2px solid black",,
                    }}>
                    <img
                      src={newspaperIcon}
                      alt=""
                      sx={{ height: "100%", width: "100%" }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography color={"common.yellow"} variant="t64_400">
                    25+
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="t16_400">Projects delivered</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
