import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import LogoOrange from "./logo";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Link from "@mui/material/Link";

import {useLocation, useNavigate } from "react-router";
import { SocialLinks } from "../utils/config";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContactEmailAction, contactUsWindowScrollAction, serviceWindowScrollAction } from "../redux/actions/action";
import VeeTeeLogo from "../../src/asset/vttechLogo.png"

function QuickAccess() {

  const [windowTop , setWindowTop] = useState(0)
  const preventDefault = (event) => event.preventDefault();

  const dispatch = useDispatch();

  const handleChange = (page) => {
    if (page === "/contactUs") {
      dispatch(contactUsWindowScrollAction(0));
      navigate("/contactUs");
       window.scroll(900,900)
       window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    } else if (page === "/service") {
      dispatch(serviceWindowScrollAction(0));
      navigate("/service");
      window.scroll(900,900)
      window.scrollTo({
     top: 0,
     behavior: "smooth",
   });
    } else if (page === "/careers") {
      dispatch(serviceWindowScrollAction(0));
      navigate("/careers");
      window.scroll(900,900)
      window.scrollTo({
     top: 0,
     behavior: "smooth",
   });
    }else if (page === "/aboutUs") {
      dispatch(serviceWindowScrollAction(0));
      navigate("/aboutUs");
      window.scroll(900,900)
      window.scrollTo({
     top: 0,
     behavior: "smooth",
   });
    }
  }
  let navigate = useNavigate();

  const handleServiceChange = (windowScroll) => {
    dispatch(serviceWindowScrollAction(windowScroll));
    
    navigate("/service");
     window.scroll(900,900)
       window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }

  const navigateHome = () => {
    navigate("/")
    window.scroll(900, 900)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  let { pathname } = useLocation();
  console.log('xvxvxvx',pathname);


  console.log('');
  return (
    <Container maxWidth="xl">
      <Grid container display="flex" spacing={7}>
        <Grid item lg={4} md={4} sm={9} xs={12}>
          <Grid
            container
            spacing={3}
            display="flex"
            flexDirection="row"
            alignItems="center">
            <Grid
              item
              lg={2.5}
              md={2.5}
              sm={4}
              xs={4}
              display="flex"
              justifyContent="flex-start">
              {/* <Box
                sx={{
                  height: "50%",
                  width: "50%",
                }}> */}
              {/* <LogoOrange /> */}
              <img
                  src={VeeTeeLogo}
                  alt={"No Img"}
                onClick={navigateHome}
                />
              {/* </Box> */}
            </Grid>
            <Grid item lg={9.5} md={9.5} sm={8} xs={8}>
              <Typography variant="t32_600">{"VeeTee Technologies"}</Typography>
            </Grid>
            <Grid item lg={1} md={1.5} sm={1} xs={2}>
              <IconButton
                onClick={() => {
                  window.open(SocialLinks.facebook, "_blank");
                }}
                title={SocialLinks.facebook}
                sx={{
                  ":hover": {
                    color: "common.orange",
                    backgroundColor: "common.lightOrange",
                  },
                  color: "common.quickAccess",
                }}>
                <FacebookIcon />
              </IconButton>
            </Grid>
            <Grid item lg={1} md={1.5} sm={1} xs={2}>
              <IconButton
                onClick={() => {
                  window.open(SocialLinks.linkedin, "_blank");
                }}
                title={SocialLinks.linkedin}
                sx={{
                  ":hover": {
                    color: "common.orange",
                    backgroundColor: "common.lightOrange",
                  },
                  color: "common.quickAccess",
                }}>
                <LinkedInIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
            onClick={preventDefault}>
            <Typography variant="t18_700_L15" pb="20px" color="common.black">
              {" "}
              {"Solutions"}{" "}
            </Typography>
            <NavLink to="/service"  onClick={()=> handleServiceChange(1100)}>
              <Typography variant="t13_400" color="common.quickAccess">
                {"Application Development"}
              </Typography>
            </NavLink>
            <NavLink to="/service" onClick={()=> handleServiceChange(1700)}>
              <Typography variant="t13_400" color="common.quickAccess">
                {"Web Scraping"}
              </Typography>
            </NavLink>
            <NavLink to="/service" onClick={()=> handleServiceChange(2200)}>
              <Typography variant="t13_400" color="common.quickAccess">
                {"Data Analytics"}
              </Typography>
            </NavLink>
            <NavLink to="/service"  onClick={()=> handleServiceChange(2700)}>
              <Typography variant="t13_400" color="common.quickAccess">
                {"UI & UX"}
              </Typography>
            </NavLink>
          </Box>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
            onClick={preventDefault}
          >
            <Typography variant="t18_700_L15" pb="20px" color="common.black">
              {" "}
              {"VeeTee"}{" "}
            </Typography>
            <NavLink to="/aboutUs" onClick={()=>handleChange("/aboutUs")}>
              <Typography variant="t13_400" color="common.quickAccess">
                {"About Us"}
              </Typography>
            </NavLink>
            <NavLink to="/service" onClick={()=>handleChange("/service")}>
              <Typography variant="t13_400" color="common.quickAccess">
                {"Services"}
              </Typography>
            </NavLink>
            <NavLink to="/careers" onClick={()=>handleChange("/careers")}>
              <Typography variant="t13_400" color="common.quickAccess">
                {"Careers"}
              </Typography>
            </NavLink>
            <NavLink to="/contactUs" onClick={()=>handleChange("/contactUs")}>
              <Typography variant="t13_400" color="common.quickAccess">
                {"Contact Us"}
              </Typography>
            </NavLink>
          </Box>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
            onClick={preventDefault}>
            <Typography variant="t18_700_L15" pb="20px" color="common.black">
              {" "}
              {"Contacts"}{" "}
            </Typography>
            <Link href="#" underline="hover">
              <Typography variant="t15_400" color="common.quickAccess">
                {"veetee@vttech.in"}
              </Typography>
            </Link>
            <Link href="#" underline="hover">
              <Typography variant="t15_400" color="common.quickAccess">
                {"+91-44-4211 7914"}
              </Typography>
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          color="common.end">
          <Box direction="row">
            <Typography variant="t13_400" pr="20px">
              {"© 2023 VeeTee Technologies. All rights reserved."}
            </Typography>
            <Typography variant="t13_400" pr="20px">
              {"Privacy Policy"}
            </Typography>
            <Typography variant="t13_400" pr="20px">
              {"Terms of Use"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default QuickAccess;
