import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";

function CardTemp(props) {
  const { img, title, content } = props;
  return (
    <Card
      sx={{
        ":hover": {
          boxShadow: (theme) => `2px 3px 13px 13px ${theme.palette.grey[300]}`,
        },
        p: "30px",
        backgroundColor: "#F9FAFB",
        color: "common.black",
        position: "relative",
        maxHeight: { xs: 310, sm: 320, md: 320 },
        minHeight: { xs: 300, sm: 310, md: 310 },
        maxWidth: { xs: 290, sm: 320, md: 420 },
        minWidth: { xs: 280, sm: 310, md: 410 },
        // border: "2px solid black",,
      }}>
      <Grid
        container
        spacing={5}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "relative",
              maxHeight: { xs: 50, sm: 60, md: 70 },
              minHeight: { xs: 45, sm: 55, md: 65 },
              maxWidth: { xs: 50, sm: 60, md: 70 },
              minWidth: { xs: 45, sm: 55, md: 65 },
              // border: "2px solid black",,
            }}>
            <img
              src={img}
              alt={"NoImage"}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          {title}
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Typography align="center">{content}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default CardTemp;
