import React from "react";
import ChallengeSolutionTemp from "../projects/tempChallengeSolution";
import GridTempTextImage1 from "../projects/gridTemp1";

export default function ChallengeSolutio() {
  const challengeContent = [
    {
      challengeContent:
        "Oracle Discoverer attained EOL and the Business was caught off-guard. There was no time left. As all other divisions using Discoverer had moved on, the entire server needed to be paid for.",
    },
  ];
  const solutionContent = [
    {
      solutionContent:
        "Migrated reports, charts from Oracle Discoverer to Tableau and created advanced data visualizations",
    },
  ];

  return (
    <ChallengeSolutionTemp
      challengeContent={challengeContent}
      solutionContent={solutionContent}
    />
  );
}
