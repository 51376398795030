import { CAREER_EMAIL, CONTACTUS_WINDOW_SCROLL, CONTACT_US_EMAIL, SERVICE_WINDOW_SCROLL } from "../actionTypes";
import Service from "../../services/index";
import { ErrorAlert, FailLoad, ListLoad } from "../load";

export const ContactEmailAction = (data) => async (dispatch) => {
  try {
    console.log("action", data);
    const res = await Service.contactEmail(data);
    if (res.status === 200) {
      dispatch({
        type: CONTACT_US_EMAIL,
        payload: res.data,
      });
    }
  } catch (err) {
    ErrorAlert(dispatch, err);
  }
};

export const CareerEmailAction = (data) => async (dispatch) => {
  try {
    const res = await Service.careerEmail(data);
    if (res.status === 200) {
      dispatch({
        type: CAREER_EMAIL,
        payload: res.data,
      });
    }
  } catch (err) {
    ErrorAlert(dispatch, err);
  }
};

export const ProjectEmailAction = (data) => async (dispatch) => {
  try {
    const res = await Service.projectEmail(data);
    if (res.status === 200) {
      dispatch({
        type: CAREER_EMAIL,
        payload: res.data,
      });
    }
  } catch (err) {
    ErrorAlert(dispatch, err);
  }
};


export const contactUsWindowScrollAction = (data) => async (dispatch) => {
  try {
   
      dispatch({
        type: CONTACTUS_WINDOW_SCROLL,
        payload: data,
      });
    
  } catch (err) {
    console.log(err);
  }
};

export const serviceWindowScrollAction = (data) => async (dispatch) => {
  try {
   
      dispatch({
        type: SERVICE_WINDOW_SCROLL,
        payload: data,
      });
    
  } catch (err) {
    console.log(err);
  }
};
export const aboutUsWindowScrollAction = (data) => async (dispatch) => {
  try {
   
      dispatch({
        type: SERVICE_WINDOW_SCROLL,
        payload: data,
      });
    
  } catch (err) {
    console.log(err);
  }
};
export const careersWindowScrollAction = (data) => async (dispatch) => {
  try {
   
      dispatch({
        type: SERVICE_WINDOW_SCROLL,
        payload: data,
      });
    
  } catch (err) {
    console.log(err);
  }
};



