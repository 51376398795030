import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ProjectEmailAction } from "../../redux/actions/action";

export default function RequestProposal() {
  const dispatch = useDispatch();

  const [formValue, setFormValues] = useState({
    email: null,
  });

  const [formErrors, setFormErrors] = useState({
    email: null,
  });

  const [requiredFields] = useState(["email"]);

  const [validRegex, setValidRegex] = useState({
    email: false,
    phone_number: false,
  });

  const [regex] = useState({});

  const validationHandler = (name, value) => {
    if (!Object.keys(formErrors).includes(name)) return;

    if (
      requiredFields.includes(name) &&
      (value === null ||
        value === "null" ||
        value === "" ||
        value === false ||
        (Object.keys(value) && value.value === null))
    ) {
      setFormErrors({
        ...formErrors,
        [name]: capitalize(name) + " is Required!",
      });
    } else if (regex[name]) {
      if (!regex[name].test(value)) {
        setFormErrors({
          ...formErrors,
          [name]: capitalize(name) + " is Invalid!",
        });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: null,
        });
      }
    } else {
      setFormErrors({
        ...formErrors,
        [name]: null,
      });
    }
  };

  const setStateHandler = async (name, value) => {
    let formObj = {};

    formObj = {
      ...formValue,
      [name]: value === "" ? null : value,
    };

    await setFormValues(formObj);
    // validationHandler(name, value);
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleChange = async (e) => {
    let { name, value } = e.target;
    setStateHandler(name, value);
    // console.log("form change", formValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;
    let formErrorsObj = { ...formErrors };
    await Object.keys(formValue).map((key, i) => {
      if (
        requiredFields.includes(key) &&
        (formValue[key] === null || formValue[key] === "")
      ) {
        isValid = false;
        formErrorsObj[key] = capitalize(key) + " is Required!";
      } else if (regex[key]) {
        if (!regex[key].test(formValue[key])) {
          isValid = false;
          formErrorsObj[key] = capitalize(key) + " is Invalid!";
        }
      }
      return null;
    });

    await setFormErrors(formErrorsObj);
    // alert("ok");

    if (isValid) {
      const data = {
        email: formValue.email,
      };
      console.log("form submit1", data);
      dispatch(ProjectEmailAction(data));
    } else {
      console.log("form error");
    }
  };
  return (
    <Container maxWidth="xl">
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={5}
        sx={{
          backgroundColor: "common.lightOrange",
        }}>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Typography variant="t52_700">
            Request Proposal To Access More Project Cases
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Grid container spacing={5}>
            <Grid item lg={12} md={6} sm={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "18px",
                  lineHeight: "24px",
                  // width: "593px",
                }}>
                Pellentesque habitant morbi tristique senectus. Sit amet mauris
                commodo quis imperdiet massa. Duis ut diam quam nulla porttitor.
                Id diam maecenas ultricies mi.
              </Typography>
            </Grid>
            <Grid
              item
              lg={12}
              md={6}
              sm={12}
              display="flex"
              alignItems="center">
              <Grid container spacing={5}>
                <Grid item lg={6} md={8} sm={8} xs={8}>
                  <TextField
                    sx={{ backgroundColor: "common.white" }}
                    name="email"
                    onChange={handleChange}
                    placeholder="Enter Your Email"
                    size="small"
                    fullWidth={true}
                    required={true}
                    // type="text"
                    error={formErrors.email === null ? false : true}
                    helperText={
                      formErrors.email === null ? "" : formErrors.email
                    }
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={4}
                  xs={4}
                  display="flex"
                  justifyContent={"center"}>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    sx={{
                      backgroundColor: "common.yellow",
                      border: `1.5px solid #FFB800`,
                      color: "common.white",
                      width: "100%",
                      height: "100%",
                      ":hover": {
                        color: "common.yellow",
                        border: `1.5px solid #FFB800`,
                      },
                    }}>
                    <Typography variant="t18_500">Submit</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>
                Faucibus a pellentesque sit amet porttitor eget dolor morbi non.
                Feugiat nisl
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
