import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { reset_bt, submit_bt } from "../../label";
import { useDispatch, useSelector } from "react-redux";
import { ContactEmailAction } from "../../redux/actions/action";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

function GetInTouch() {
  const dispatch = useDispatch();

  const {
    AdminReducer: { contact },
  } = useSelector((state) => state);

  // const ContactSchema = Yup.object().shape({
  //   // firstName: Yup.string("Enter your User Id")
  //   //   .min(2, "Too Short!")
  //   //   .max(50, "Too Long!")
  //   //   .required("First Name is required"),
  //   // lastName: Yup.string("Enter your User Name")
  //   //   .min(2, "Too Short!")
  //   //   .max(100, "Too Long!")
  //   //   .required("Last Name is required"),
  //   // number: Yup.string()
  //   //   .matches(new RegExp("[0-9]{10}"), "Invalid Number")
  //   //   .required("Contact Number is required"),
  //   // email: Yup.string()
  //   //   .email("Email must be a valid email address")
  //   //   .required("Email is required"),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     firstName: null,
  //     lastName: null,
  //     email: null,
  //     number: null,
  //     msg: null,
  //   },

  //   validationSchema: ContactSchema,

  //   onSubmit: () => {
  //     console.log("initial");
  //     let values = { ...formik.values };
  //     console.log("log", values);
  //     const data = {
  //       firstName: values.firstName,
  //       lastName: values.lastName,
  //       email: values.email,
  //       number: values.number,
  //       msg: values.msg,
  //     };
  //     console.log("log data", data);
  //     dispatch(ContactEmailAction({firstName:"karan"}));
  //   },
  // });

  // const {
  //   errors,
  //   touched,
  //   handleSubmit,
  //   values,
  //   getFieldProps,
  //   setFieldValue,
  // } = formik;

  //.................................................................................................................................................

  const [formValue, setFormValues] = useState({
    firstName: null,
    lastName: null,
    email: null,
    number: null,
    msg: null,
  });

  const [formErrors, setFormErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    number: null,
  });

  const [requiredFields] = useState([
    "firstName",
    "lastName",
    "email",
    "number",
  ]);

  const [validRegex, setValidRegex] = useState({
    email: false,
    phone_number: false,
  });

  const [regex] = useState({});

  const validationHandler = (name, value) => {
    if (!Object.keys(formErrors).includes(name)) return;

    if (
      requiredFields.includes(name) &&
      (value === null ||
        value === "null" ||
        value === "" ||
        value === false ||
        (Object.keys(value) && value.value === null))
    ) {
      setFormErrors({
        ...formErrors,
        [name]: capitalize(name) + " is Required!",
      });
    } else if (regex[name]) {
      if (!regex[name].test(value)) {
        setFormErrors({
          ...formErrors,
          [name]: capitalize(name) + " is Invalid!",
        });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: null,
        });
      }
    } else {
      setFormErrors({
        ...formErrors,
        [name]: null,
      });
    }
  };

  const setStateHandler = async (name, value) => {
    let formObj = {};

    formObj = {
      ...formValue,
      [name]: value === "" ? null : value,
    };

    await setFormValues(formObj);
    // validationHandler(name, value);
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleChange = async (e) => {
    let { name, value } = e.target;
    setStateHandler(name, value);
    // console.log("form change", formValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;
    let formErrorsObj = { ...formErrors };
    await Object.keys(formValue).map((key, i) => {
      if (
        requiredFields.includes(key) &&
        (formValue[key] === null || formValue[key] === "")
      ) {
        isValid = false;
        formErrorsObj[key] = capitalize(key) + " is Required!";
      } else if (regex[key]) {
        if (!regex[key].test(formValue[key])) {
          isValid = false;
          formErrorsObj[key] = capitalize(key) + " is Invalid!";
        }
      }
      return null;
    });

    await setFormErrors(formErrorsObj);
    // alert("ok");

    if (isValid) {
      const data = {
        msg: formValue.msg,
        firstName: formValue.firstName,
        number: formValue.number,
        lastName: formValue.lastName,
        email: formValue.email,
      };
      console.log("form submit1", data);
      dispatch(ContactEmailAction(data));
      //   axios.post(`http://localhost:4000/admin/contact`, {
      //     "msg": "jhhhg",
      //     "firstName": "lklklk",
      //     "number": "09846025974",
      //     "lastName": "ffff@fgh.ghj",
      //     "email": "mkaran71999@gmail.com"
      // });
      // console.log("form submit2", data);
    } else {
      console.log("form error");
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const handleReset = () => {};
  return (
    <Container maxWidth="xl">
      <Grid container spacing={5}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={3} pb="20px">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t60_400">Get in touch!</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t20_400">
                We are not “miles” away but just a “mail” away!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {/* <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}> */}
          <Grid container spacing={3} pb="20px">
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="t16_400_L24">First name*</Typography>
              <TextField
                name="firstName"
                onChange={handleChange}
                placeholder="first name"
                size="small"
                fullWidth={true}
                required={true}
                // type="text"
                error={formErrors.firstName === null ? false : true}
                helperText={
                  formErrors.firstName === null ? "" : formErrors.firstName
                }
                // error={Boolean(touched.firstName && errors.firstName)}
                // helperText={touched.firstName && errors.firstName}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="t16_400_L24">Last name*</Typography>
              <TextField
                name="lastName"
                onChange={handleChange}
                placeholder="last name"
                size="small"
                fullWidth={true}
                required={true}
                // type="text"
                error={formErrors.lastName === null ? false : true}
                helperText={
                  formErrors.lastName === null ? "" : formErrors.lastName
                }
                // error={Boolean(touched.lastName && errors.lastName)}
                // helperText={touched.lastName && errors.lastName}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="t16_400_L24">Email*</Typography>
              <TextField
                name="email"
                onChange={handleChange}
                placeholder="email"
                size="small"
                fullWidth={true}
                required={true}
                // type="text"
                error={formErrors.email === null ? false : true}
                helperText={formErrors.email === null ? "" : formErrors.email}
                // error={Boolean(touched.email && errors.email)}
                // helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="t16_400_L24">Contact Number*</Typography>
              <TextField
                placeholder="contact number"
                onChange={handleChange}
                name="number"
                size="small"
                fullWidth={true}
                required={true}
                // type="number"
                error={formErrors.number === null ? false : true}
                helperText={formErrors.number === null ? "" : formErrors.number}
                // error={Boolean(touched.number && errors.number)}
                // helperText={touched.number && errors.number}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t16_400_L24">Message</Typography>
              <TextField
                name="msg"
                onChange={handleChange}
                placeholder="message"
                size="small"
                fullWidth={true}
                // type="text"
                maxRows={5}
                minRows={5}
                inputProps={{ maxLength: 50 }}
                multiline
                rows={5}
                //  sx={{ maxWidth: 200 }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ReCAPTCHA
                sitekey="6LepiREkAAAAALu7BWPMMN2IsUWHz_9O6dtxQvqd"
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end">
            <Grid item display="flex" justifyContent="flex-end">
              <Button
                onClick={handleReset}
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid black`,
                  color: "common.black",
                  width: "100%",
                  height: "100%",
                }}>
                <Typography variant="t18_500">{reset_bt}</Typography>
              </Button>
            </Grid>
            <Grid item display="flex" justifyContent="flex-end">
              <Button
                onClick={handleSubmit}
                type="submit"
                sx={{
                  backgroundColor: "common.orange",
                  border: `1.5px solid #FD7E3B`,
                  color: "common.white",
                  width: "100%",
                  height: "100%",
                  ":hover": {
                    color: "common.orange",
                    border: `1.5px solid #FD7E3B`,
                  },
                }}>
                <Typography variant="t18_500">{submit_bt}</Typography>
              </Button>
            </Grid>
          </Grid>
          {/* </Form>
        </FormikProvider> */}
        </Grid>
      </Grid>
    </Container>
  );
}

export default GetInTouch;
