import React from "react";
import CPSTTemp from "../projects/tempCpst";
import FeaturesBenifitsTemp from "../projects/tempFeaturesBenifits";

export default function SupplyChainDefectFB() {
  const featuresBenifitsContentLeft = [
    {
      featuresBenifitsContentLeft:
        "Incident reporting so that customer initiated or order management staff initiated incident details can be recorded.",
    },
    {
      featuresBenifitsContentLeft:
        "Internal initiation so that the prioritizing, linking to order, routing of incidents can be done based on initial investigation.",
    },
    {
      featuresBenifitsContentLeft:
        "Investigation so that Root causes, impact, corrective and preventive actions can be recorded.",
    },
    {
      featuresBenifitsContentLeft:
        "Review so that the team leads can review the resolution.",
    },
    {
      featuresBenifitsContentLeft:
        "Approve so that the order management executive can close the incident after resolution and customer can be notified.",
    },
    {
      featuresBenifitsContentLeft:
        "Audit history to record every actions related to the incident reported for compliance purposes.",
    },
  ];

  const featuresBenifitsContentRight = [
    {
      featuresBenifitsContentRight:
        "Monthly and Quarterly executive defect summaries so that actions are taken for improvement in operations.",
    },
    {
      featuresBenifitsContentRight: "SLA/KPI Reports to measure performance",
    },
    {
      featuresBenifitsContentRight:
        "Detailed Report on Incidents classified by top issue categories, top suppliers, closure categories, top customers, sourcing locations.",
    },
    {
      featuresBenifitsContentRight:
        "Intelligent Search so that user can search incident based on 20 different attribute combinations to filter incidents that are needed for actions to be performed.",
    },
    {
      featuresBenifitsContentRight:
        "Intelligent audit history so that the compliance requirements and future reference requirements are met.",
    },
  ];

  return (
    <FeaturesBenifitsTemp
      featuresBenifitsContentLeft={featuresBenifitsContentLeft}
      featuresBenifitsContentRight={featuresBenifitsContentRight}
    />
  );
}
