import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";

export default function IndustriesTemp(props) {
  const { IndustriesContentTop } = props;
  console.log("IndustriesContentTop", IndustriesContentTop);
  return (
    <Grid container spacing={5}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          container
          display={"flex"}
          flexDirection="row"
          justifyContent={"center"}>
          <Typography variant="t52_200_L60" color={"common.white"}>
            Industries Our
          </Typography>
          <Typography variant="t52_700_L60" color={"#114796"} pl="15px">
            Experts Serve
          </Typography>
        </Grid>
        {/* <Typography
          
          sx={{ display: "flex", justifyContent: "center" }}
        >
          Industries Our <span color="red">Experts Serve</span>
        </Typography> */}
      </Grid>
      {/* <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item>
          <img
            src={img}
            style={{ height: "70px", width: "70px" }}
          ></img>
        </Grid>
        <Grid item>
          <Typography width="20px">{imgContent}</Typography>
        </Grid>
      </Grid> */}

      {IndustriesContentTop.map((t, i) => (
        <>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Grid
              container
              display="flex"
              flexDirection="row"
              spacing={4}
              p="2% 17%"
              alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box display="flex" direction="row" alignItems="center">
                  <img
                    src={t.img}
                    alt=""
                    style={{ height: "60px", width: "60px" }}></img>
                  <Typography
                    sx={{ color: "common.white" }}
                    variant="t20_400"
                    pl="20px">
                    {t.imgContent}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      ))}
    </Grid>
  );
}
