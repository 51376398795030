import { Container, Grid, Typography } from "@mui/material";
import React from "react";

export default function AreYouWilling() {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={7}
        display="flex"
        flexDirection="row-reverse"
        alignItems="center"
        justifyContent="center">
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item lg={1} md={1} sm={6} xs={1}></Grid>
        <Grid item lg={6} md={6} sm={6} xs={1}></Grid>
        <Grid item lg={6} md={12 } sm={12} xs={12}>
          <Typography variant="t52_400_L70">
            Are You Willing To Explore Our Works Before Outsourcing Your IT
            Projects To Us?
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
