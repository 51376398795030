import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";

function GridTempTextImage2(props) {
  const {
    title,
    subTitle,
    image,
    content,
    launch,
    project,
    benefit,
    button,
    pageUrl,
    type,
  } = props;
  let navigate = useNavigate();

  function pageNavigate() {
    navigate(pageUrl);
    window.scroll(0, 0);
  }
  return (
    <Container maxWidth="xl">
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={7}>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "relative",
              maxHeight: { xs: 90, sm: 460, md: 520, lg: 360 },
              minHeight: { xs: 80, sm: 450, md: 510, lg: 350 },
              maxWidth: { xs: 260, sm: 560, md: 620, lg: 620 },
              minWidth: { xs: 250, sm: 550, md: 610, lg: 610 },
              // border: "2px solid black",,
            }}>
            {" "}
            <img src={image} alt="" style={{ height: "90%", width: "90%" }} />
          </Box>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={3}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="t52_700"
                sx={{
                  textAlign: "left",
                }}>
                {title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t14_400" color={"#00BA90"}>
                {subTitle}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {content.map((m) => (
                <>
                  <Grid container display="flex" flexDirection="row">
                    <Grid item lg={11} md={11} sm={11} xs={12} pb="20px">
                      <Typography variant="t18_400">{m.content}</Typography>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container display="flex" flexDirection="row">
                <Grid item lg={3} md={2} sm={2} xs={12}>
                  <Typography variant="t18_400" color={"#FFB800"}>
                    Benefits:
                  </Typography>
                </Grid>
                <Grid item lg={9} md={10} sm={10} xs={12}>
                  <Typography variant="t18_400">{benefit}</Typography>
                </Grid>
              </Grid>
              <Grid container display="flex" flexDirection="row">
                <Grid item lg={3} md={2} sm={2} xs={12}>
                  <Typography variant="t18_400" color={"#FFB800"}>
                    MVP Launch:
                  </Typography>
                </Grid>
                <Grid item lg={3} md={10} sm={10} xs={12}>
                  <Typography variant="t18_400">{launch}</Typography>
                </Grid>
              </Grid>
              <Grid container display="flex" flexDirection="row">
                <Grid item lg={3} md={2} sm={2} xs={12}>
                  <Typography variant="t18_400" color={"#FFB800"}>
                    Project Status:
                  </Typography>
                </Grid>
                <Grid item lg={9} md={10} sm={10} xs={12}>
                  <Typography variant="t18_400">{project}</Typography>
                </Grid>
              </Grid>
              <Grid item lg={3} md={2} sm={3} xs={12} pt="20px">
                {type === "view" && (
                  <Button
                    sx={{
                      backgroundColor: "common.yellow",
                      border: `1.5px solid #FFB800`,
                      color: "common.white",
                      width: "100%",
                      height: "100%",
                      ":hover": { color: "common.yellow" },
                    }}
                    onClick={pageNavigate}>
                    <Typography variant="t18_400">{button}</Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default GridTempTextImage2;
