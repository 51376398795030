import React from "react";
import CPSTTemp from "../projects/tempCpst";
import FeaturesBenifitsTemp from "../projects/tempFeaturesBenifits";

export default function Benefits() {
  const featuresBenifitsContentLeft = [
    {
      featuresBenifitsContentLeft: "Upgrading database instances",
    },
    {
      featuresBenifitsContentLeft: "Performance tuning",
    },
    {
      featuresBenifitsContentLeft: "Running regression tests",
    },
  ];

  const featuresBenifitsContentRight = [
    {
      featuresBenifitsContentRight:
        "Uncovered customer/data-specific issues with 12c and addressed them with Oracle Corporation and came up with fixes in a short time",
    },
    {
      featuresBenifitsContentRight:
        "Additional performance tuning measures brought 10% performance boosts that Business is now able to process 50 jobs in parallel versus 40 jobs in 11g",
    },
  ];

  return (
    <FeaturesBenifitsTemp
      featuresBenifitsContentLeft={featuresBenifitsContentLeft}
      featuresBenifitsContentRight={featuresBenifitsContentRight}
    />
  );
}
