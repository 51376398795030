import { OPEN_ALERT, CLOSE_ALERT } from "./actionTypes";

export const OpenalertActions = (payload) => async (dispatch) => {
  dispatch({
    type: OPEN_ALERT,
    payload,
  });
};

export const ClosealertActions = () => async (dispatch) => {
  dispatch({
    type: CLOSE_ALERT,
  });
};
