import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ServiceImg1 from "../../asset/servicesImg1.svg";

function We() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={5} display="flex" flexDirection="row">
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              position: "relative",
              maxHeight: { xs: 210, sm: 360, md: 260, lg: 360 },
              minHeight: { xs: 200, sm: 350, md: 250, lg: 350 },
              maxWidth: { xs: 330, sm: 560, md: 410, lg: 620 },
              minWidth: { xs: 320, sm: 550, md: 400, lg: 610 },
              // border: "2px solid black",
            }}>
            <img
              src={ServiceImg1}
              alt="NoImage"
              style={{ position: "absolute", height: "100%", width: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t32_700">We Listen to Understand</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_400">
                Our service starts with understanding your business architecture
                and customer journeys unlike some of our competitors who jump
                straight into solving your problems with technology.
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_400">
                We then sprint to understand and solve your problems with cost
                effective and cutting edge technologies.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default We;
