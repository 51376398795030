import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import SkillIntro from "../../asset/skillsIntro.png";
import SkillBg from "../../asset/skillImg1bg.png";
import SkillC from "../../asset/skillImg1C.png";
import SkillWeb from "../../asset/skillImg1web.png";

function Skill() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={5}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid container spacing={5}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {" "}
              <Typography variant="t60_700">
                {"Our Core Skills"}
              </Typography>{" "}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_500">
                {
                  "We develop, test and operate your applications using Agile (Scrum), Traditional (Waterfall) and Hybrid Methodologies. "
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center">
          <Box
            sx={{
              position: "relative",
              maxHeight: { xs: 260, sm: 460, md: 620 },
              minHeight: { xs: 250, sm: 450, md: 610 },
              maxWidth: { xs: 260, sm: 460, md: 620 },
              minWidth: { xs: 250, sm: 450, md: 610 },
              // border: "2px solid black",,
            }}>
            <img
              src={SkillBg}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "75%",
                width: "75%",
                top: "10%",
                left: "15%",
              }}
            />
            <img
              src={SkillIntro}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "95%",
                width: "95%",
                bottom: "10%",
                left: "7%",
              }}
            />
            <img
              src={SkillC}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "30%",
                width: "30%",
                bottom: "75%",
                right: "70%",
              }}
            />
            <img
              src={SkillWeb}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "35%",
                width: "45%",
                top: "65%",
                left: "60%",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Skill;
