import { Grid, Typography } from "@mui/material";
import React from "react";
import YellowCardTemp from "../yellowCardTemp";
import Laptop from "../../asset/lap45.png";
import Monitor from "../../asset/supplyChainImg.png";

export default function SupplyChainDefectMain(props) {
  const title = "Supply Chain Defect Management";

  const content =
    "Supply Chain Defect Management application is used by a multinational consumer goods manufacturer that has a supply chain covering Europe, North America and Asia Pacific.";

  return (
    <YellowCardTemp
      title={title}
      content={content}
      image={Laptop}
      image2={Monitor}
      type={"left"}
    />
  );
}
