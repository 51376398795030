import { CAREER_EMAIL, CONTACTUS_WINDOW_SCROLL, CONTACT_US_EMAIL, PROJECT_EMAIL, SERVICE_WINDOW_SCROLL } from "../actionTypes";

const initialState = {
  contact: [],
  carrer: [],
  project: [],
  contact_window: 0,
  service_window:0
};

function AdminReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONTACT_US_EMAIL:
      return { ...state, contact: payload };

    case CAREER_EMAIL:
      return { ...state, carrer: payload };

    case PROJECT_EMAIL:
      return { ...state, project: payload };
    
    case CONTACTUS_WINDOW_SCROLL:
      return { ...state, contact_window: payload };
    
    case SERVICE_WINDOW_SCROLL:
      return { ...state, service_window: payload };

    default:
      return state;
  }
}

export default AdminReducer;
