import { Grid } from "@mui/material";
import React from "react";
import BussinessAnalytics from "../../components/home/bussinessAnalytics";
import Intro from "../../components/home/intro";
import UiDesign from "../../components/home/uiDesign";
import WebScraping from "../../components/home/webScraping";
import Strength from "../../components/aboutUs/strength";
import EndTemp from "../../components/aboutUs/endTemp";
import Details from "../../components/home/details";
import Industries from "../../components/home/Industries";
import ResponsiveAppBar from "../../layout/appBar";
import LogoWhite from "../../asset/logoWhite.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";
import Technologies from "../../components/home/technologies";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { contactUsWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";
// import { TabTitle } from "../../utils/commonFunction";

function Home() {
  // TabTitle("VEETEE - Home");
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const handleGetInTouch = () => {
    navigate("/contactUs");
    // window.scroll(900,900)
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
    dispatch(contactUsWindowScrollAction(650));

  };
  return (
    <Grid container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VEETEE - Home</title>
        <link rel="canonical" href="http://localhost:3000/" />
        <meta name='title' content={"title"} />
        <meta name='description' content={"description"} />
      </Helmet>
      <ResponsiveAppBar
        navBarBgColor={"common.homeGreen"}
        ButtonBgcolor={"common.orange"}
        logo={LogoWhite}
        ButtonBorder={"transparent"}
        bText={"common.white"}
        text={"common.white"}
        hoverColor={"common.white"}
        hBorderColor={"#FFFFFF"}
        colorr={"red"}
        handleGetInTouch={handleGetInTouch}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        backgroundColor="common.homeGreen">
        <Intro />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <BussinessAnalytics />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        backgroundColor="common.blue">
        <WebScraping />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <UiDesign />
      </Grid>
      {/* <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Details />
      </Grid> */}
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Strength />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ backgroundColor: "common.orange", p: "5% 3%" }}>
        <Industries />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Technologies />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        bgcolor="common.lightOrange">
        <EndTemp />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Home;
