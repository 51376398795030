import React from "react";
import CPSTTemp from "../projects/tempCpst";
import FeaturesBenifitsTemp from "../projects/tempFeaturesBenifits";

export default function ShippingExecutionFB() {
  const featuresBenifitsContentLeft = [
    {
      featuresBenifitsContentLeft:
        "Orders list – so that the suppliers and transporters can work on each order.",
    },
    {
      featuresBenifitsContentLeft:
        "Document Management - so that certificate of analysis and safety data sheets needed for each order can be extracted and embedded with the consignment.",
    },
    {
      featuresBenifitsContentLeft:
        "Integration with Customer Shipping Requirements tool so that shipping instructions can be handed over to the transporters.",
    },
    {
      featuresBenifitsContentLeft:
        "User Administration (Provision, update, remove) - so that unauthorized access is prevented",
    },
    {
      featuresBenifitsContentLeft:
        "Links to other business portals so that the users can easily obtain information needed to complete their actions.",
    },
  ];

  const featuresBenifitsContentRight = [
    {
      featuresBenifitsContentRight:
        "Help articles so that the users can obtain guides to perform their actions",
    },
    {
      featuresBenifitsContentRight:
        "Order dashboard segregated by regions so that operations can be monitored, controlled and improved as per regions",
    },
    {
      featuresBenifitsContentRight:
        "Integration with Supply chain defect reporting so that the hosting cost for the client could be saved with a single host and user need not use two different portals.",
    },
    {
      featuresBenifitsContentRight:
        "Integration with corporate LDAP for authentication and authorization",
    },
  ];

  return (
    <FeaturesBenifitsTemp
      featuresBenifitsContentLeft={featuresBenifitsContentLeft}
      featuresBenifitsContentRight={featuresBenifitsContentRight}
    />
  );
}
