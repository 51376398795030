import { Grid, Typography } from "@mui/material";
import React from "react";
import YellowCardTemp from "../yellowCardTemp";
import Laptop from "../../asset/lap12.png";
import Code from "../../asset/shippingExeImg.png";

export default function ShippingExecutionMain(props) {
  const title = "Shipping Execution Management";

  const content =
    "Shipping Execution Management application is used by a chemical division of a multinational consumer goods corporation.";

  return (
    <YellowCardTemp
      title={title}
      content={content}
      image={Laptop}
      image2={Code}
      type={"right"}
    />
  );
}
