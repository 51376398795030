import React, { useEffect, useState } from "react";
import {
  Map,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  leafletElement,
} from "react-leaflet";
import { useRef } from "react";
import L from "leaflet";
import "./leaflet.css";
import MarkIcon from "../../asset/marker_icon.png";
import MapTabs from "./mapTab";
import { Box, Container } from "@mui/material";

const osm = {
  mapTiler: {
    url: "https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=MuBLmacwowIgGLtimdcn",
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
};

const markerIcon = new L.Icon({
  iconUrl: MarkIcon,
  iconSize: [40, 40],
});

const OSMap = (props) => {
  const { coordinates, setCoordinates, locationCenter } = props;
  const [center, setCenter] = useState(locationCenter);
  // OSMap.flyTo(locationCenter)
  // const dd = () => {
  //   setCenter(OSMap.flyTo(locationCenter));
  // };
  const ZOOM_LEVEL = 10;
  const mapRef = useRef();
  const [OpenIndex, setOpenIndex] = React.useState({});

  useEffect(() => {
    if (mapRef.current) {
      let map = mapRef.current.leafletElement;
      map.flyTo(locationCenter, 15, { animate: true });
    }
  }, [locationCenter]);

  // console.log(coordinates, "sdrt");

  const containerStyle = {
    // width: '400px',
    // height: '400px'
    width: "100%",
    height: "600px",
    overflow: "hidden",
    position: "relative",
    zIndex: -1,
  };

  // const l = () => {
  //   setCenter(locationCenter);
  //   showLocation();
  // };

  return (
    <Map
      style={containerStyle}
      center={center}
      zoom={ZOOM_LEVEL}
      scrollWheelZoom={false}
      ref={mapRef}>
      <TileLayer
        url={osm.mapTiler.url}
        attribution={osm.mapTiler.attribution}
      />

      {/* <Polyline
          path={coordinates.map((d) => ({
            lat: d.latitude,
            lng: d.longitude,
          }))}
          options={{
            strokeColor: "#ff2527",
            strokeOpacity: 0.75,
            strokeWeight: 2,
          }}
        /> */}

      {coordinates?.map((d, index) => {
        return (
          <>
            <Marker
              icon={markerIcon}
              key={index}
              onClick={() => {
                setOpenIndex((d) => ({ ...d, [index]: true }));
              }}
              name={d.company_name}
              position={locationCenter}
              // style={{border:'2px solid black'}}
            >
              {/* {OpenIndex[index] && (
                <Popup
                  key={index}
                  onCloseClick={() => {
                    {
                      setOpenIndex((d) => ({ ...d, [index]: false }));
                    }
                  }}>
                  <div><MapTabs /></div>
                </Popup>
              )} */}
            </Marker>
          </>
        );
      })}
    </Map>
  );
};

export default OSMap;
