import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { MenuItem, Typography } from "@mui/material";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import LogoOrange from "../asset/logoOrange.png";

export default function AppDrawer(props) {
  const { page, toggleDrawer, state, navigatePage, navBarBgColor, bText } = props;

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250, backgroundColor: navBarBgColor }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <Box
        display="flex"
        direction="row"
        alignItems="center"
        p="25px 25px 10px 25px"
        sx={{}}>
        {/* <img src={LogoOrange} alt="" style={{ width: "17%", height: "17%" }} /> */}
        <Typography
          variant="t32_700"
          color={bText}
          //   pl="10px"
        >
          {"VeeTee"}
        </Typography>
      </Box>
      <Divider />
      <List>
        {page.map((page) => (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigatePage(page.label);
              }}>
              <ListItemIcon>
                <ArrowRightOutlinedIcon fontSize="large" />
                {/* <InboxIcon /> */}
              </ListItemIcon>
              <ListItemText>
                {" "}
                <Typography variant="t16_400" textAlign="center" color={bText}>
                  {page.label}{" "}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          //   <MenuItem
          //     key={page}
          //     onClick={() => {
          //       navigatePage(page);
          //     }}>
          //     <Typography variant="t16_400" textAlign="center">
          //       {page}
          //     </Typography>
          //   </MenuItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          sx={{}}>
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
