import { Card, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import TechnologiesTemp from "../technologiesTemp";

export default function Technologies() {
  return (
    <Container maxWidth="xl">
      <Card>
        <Grid
          container
          spacing={3}
          display="flex"
          justifyContent="center"
          sx={{ backgroundColor: "white", p: "2% 0%" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <Typography variant="t52_400">Technologies We Implement For You</Typography> */}
            <Grid
              container
              display={"flex"}
              flexDirection="row"
              justifyContent={"center"}>
              <Typography variant="t52_200_L60" color={"#114796"}>
                Technologies We
              </Typography>
              <Typography variant="t52_300" color={"common.orange"} pl="15px">
                Implement For You
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={9}>
            <TechnologiesTemp />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}
