import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import JoinHandsWithUs from "../../components/projects/joinHand";
import ShippingExecutionCpst from "../../components/shippingExecutionManagement/cpstShippingExecution";
import ShippingExecutionCs from "../../components/shippingExecutionManagement/csShippingExecution";
import ShippingExecutionFB from "../../components/shippingExecutionManagement/fbShippingExecution";
import ShippingExecutionMain from "../../components/shippingExecutionManagement/mainShippingExecution";
import ResponsiveAppBar from "../../layout/appBar";
import LogoBlack from "../../asset/logoBlack.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { contactUsWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";
// import { TabTitle } from "../../utils/commonFunction";

export default function ShippingExecutionManagement() {
  // TabTitle('VEETEE - Shipping Execution Management')
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGetInTouch = () => {
    // console.log("Dalapathy");
    navigate("/contactUs");
    // window.scroll(900,900)
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
    dispatch(contactUsWindowScrollAction(650));

  };
  return (
    <Grid container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VEETEE - Shipping Execution Management</title>
        <link
          rel="canonical"
          href="http://localhost:3000/projects/shippingExecutionManage"
        />
        <meta name='title' content={"title"} />
        <meta name='description' content={"description"} />
      </Helmet>
      <ResponsiveAppBar
        navBarBgColor={"common.yellow"}
        ButtonBgcolor={"transparent"}
        logo={LogoBlack}
        ButtonBorder={"black"}
        bText={"common.black"}
        text="common.black"
        handleGetInTouch={handleGetInTouch}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{
          backgroundColor: "common.yellow",
        }}
        maxHeight="50vh">
        <ShippingExecutionMain />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingExecutionCpst />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingExecutionCs />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingExecutionFB />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{
          backgroundColor: "common.lightOrange",
        }}>
        <JoinHandsWithUs />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}
