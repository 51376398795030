import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Vector from "../../asset/vectorImg1.svg";

export default function GainConfidence() {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Typography variant="t32_700_L42">
                Gain Every Confidence By Exploring Our Case Studies .
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Typography variant="t18_400">
                Leo vel orci porta non pulvinar neque. Lobortis mattis aliquam
                faucibus purus in massa tempor. Hac habitasse platea dictumst
                quisque. Bibendum at varius vel pharetra vel turpis nunc eget.
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Typography variant="t18_400">
                Id diam maecenas ultricies mi eget mauris. Faucibus interdum
                posuere lorem ipsum dolor sit amet consectetur.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <img src={Vector} alt="" height={"80%"} width={"90%"}></img>
        </Grid>
      </Grid>
    </Container>
  );
}
