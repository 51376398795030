import { Laptop } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import YellowCardTemp from "../yellowCardTemp";
import Lap from "../../asset/lap67.png";
import Monitor from "../../asset/shipReqImg.png";


export default function ShippingRequirementsMain(props) {
  const title = "Shipping Requirements Management";

  const content =
    "Shipping Execution Management application is used by a chemical division of a multinational consumer goods corporation.";

  return <YellowCardTemp title={title} content={content} image={Lap} image2={Monitor} type={"left"} />;
}
