import { Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { joinHands } from "../../label";
import { contactUsWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";

function EndTemp() {

  const dispatch = useDispatch();

  let navigate = useNavigate();
  
  const handleGetInTouch = () => {

    dispatch(contactUsWindowScrollAction(0));
    navigate("/contactUs");
    // window.scroll(900,900 , {behavior: 'smooth',})
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }
  return (
    <Container maxWidth="xl">
      <Grid container spacing={7}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Typography variant="t52_700">
            {"We just want to have the best people working for us."}
          </Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_500">
                {
                  "We pride ourselves on attracting, developing and engaging an unconventional mix of talent that enables us to be the think different, act different, deliver kind of agency we are today."
                }
              </Typography>
            </Grid>
            <Grid item lg={5} md={4} sm={6} xs={12}>
              <Button
                onClick={() => handleGetInTouch()}
                sx={{
                  backgroundColor: "common.orange",
                  border: `1.5px solid #FD7E3B`,
                  color: "common.white",
                  width: "100%",
                  height: "100%",
                  ":hover": {
                    color: "common.orange",
                    border: `1.5px solid #FD7E3B`,
                  },
                }}>
                <Typography variant="t18_500">{joinHands}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EndTemp;
