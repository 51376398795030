import { Grid, Typography } from "@mui/material";
import React from "react";
import YellowCardTemp from "../yellowCardTemp";
import Laptop from "../../asset/laptop.png";
import Monitor from "../../asset/customMainImg.png";

export default function CustomDutyMain(props) {
  const title = "Customs Duty Management";

  const content =
    "Customs Duty Management application is used by a multinational consumer goods corporation that imports raw materials from Asia and Europe into the UK.";
  // const image = { Laptop };
  return <YellowCardTemp title={title} content={content} image={Laptop} image2={Monitor} type={"left"} />;
}
