import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { exploreSolution } from "../../label";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeCardsTemp from "./homeCardsTemp";
import HomeImg2 from "../../asset/homeImg2.png";
import HImg2bg from "../../asset/hImg2bg.png";
import HImg2Chord from "../../asset/hImg2Chord.png";
import HImg2Tri from "../../asset/hImg2BlueTri.png";
import HImg2Stat from "../../asset/hImg2Stat.png";
import Case1 from "../../asset/case1.svg";
import Case2 from "../../asset/case2.svg";
import { ExploreSolutionFunc } from "../../utils/commonFunction";
import { useNavigate } from "react-router-dom";
import { serviceWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";

function BussinessAnalytics() {
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const redirect = () => {
    dispatch(serviceWindowScrollAction(0));

    navigate("/service");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const cardContent = [
    {
      img: Case1,
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
    {
      img: Case2,
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
  ];
  return (
    <Container maxWidth="xl">
      <Grid container display="flex" color="common.black" spacing={7}>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center">
          <Box
            sx={{
              position: "relative",
              maxHeight: { xs: 260, sm: 460, md: 620 },
              minHeight: { xs: 250, sm: 450, md: 610 },
              maxWidth: { xs: 260, sm: 460, md: 620 },
              minWidth: { xs: 250, sm: 450, md: 610 },
              // // border: "2px solid black",,
            }}>
            <img
              src={HImg2bg}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "70%",
                width: "70%",
                top: "34%",
                bottom: "0%",
                left: "4%",
              }}
            />
            <img
              src={HomeImg2}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "80%",
                height: "100%",
                // bottom: "62%",
              }}
            />
            <img
              src={HImg2Chord}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "25%",
                height: "12%",
                top: "95%",
                right: "65%",
              }}
            />
            <img
              src={HImg2Tri}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "13%",
                height: "12%",
                top: "28%",
                left: "68%",
              }}
            />
            <img
              src={HImg2Stat}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "25%",
                height: "25%",
                top: "80%",
                left: "50%",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Grid container spacing={5}>
            <Grid item lg={10}>
              <Typography variant="t60_700">
                {"Business Intelligence & Analytics"}
              </Typography>
            </Grid>
            <Grid item lg={11}>
              <Typography variant="t18_500">
                {
                  "VeeTee’s Business Intelligence & Analytics services enable the enterprises to grasp current trends, make accurate predictions and analyze and fight the risk factors much before they strike. We use futuristic technologies to assist the designing of BI and Analytics applications which aid enterprises to spur the ROI of businesses."
                }
              </Typography>
              <br></br>
              <br></br>
              <Typography variant="t18_500">
                {
                  "It lends a drawing of 360-degree insights about their businesses, customers, market and products. Our service portfolio comprises of Modern Data Architecture Consulting, Enterprise Data Management, IoT, Advanced Analytics, Data Visualization and Managed Analytics Services."
                }
              </Typography>
              <br></br>
              <br></br>
              <Typography variant="t18_500">
                {
                  "Our specialized team of data scientists and BI specialists have strong domain expertise. They have built profoundly authentic Machine Learning algorithms and state-of-the-art dashboards. This help clients define and manage their strategies, plans, budgets, etc. and multiply profits by providing precise, on-demand clarity to senior management."
                }
              </Typography>
              <br></br>
              <br></br>
              <Typography variant="t18_500">
                {
                  "This promotes the conversion of heir structured and unstructured data into valuable insights in real-time. The unprecedented growth of information gives rise to the unique challenge of extracting relevant information from volumes of diverse and unstructured data."
                }
              </Typography>
              <br></br>
              <br></br>
              <Typography variant="t18_500">
                {
                  "Business Intelligence (BI) & Analytics addresses these challenges by leveraging the application of Big Data. Persistent innovations have made this fast, secure and error-free while unravelling deeper and hidden insights."
                }
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Button
                onClick={redirect}
                sx={{
                  backgroundColor: "transparent",
                  border: `1px solid black`,
                  color: "common.black",
                  width: "100%",
                  height: "100%",
                }}>
                {exploreSolution}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={12}>
        <Grid container spacing={5}>
          <Grid item lg={2}>
            <Typography variant="t32_700">{"Case Studies"}</Typography>
            <Box display={"flex"} flexDirection="row" pt="20px">
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid #FD7E3B`,
                  color: "common.orange",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `0px solid whitesmoke`,
                    color: "common.white",
                  },
                  mr: "10px",
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid #FD7E3B`,
                  color: "common.orange",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `0px solid whitesmoke`,
                    color: "common.white",
                  },
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </Grid>
          {cardContent.map((m) => (
            <Grid item lg={4} md={6} sm={9} xs={12}>
              <HomeCardsTemp title={m.title} img={m.img} content={m.content} />
            </Grid>
          ))}
        </Grid>
      </Grid> */}
      </Grid>
    </Container>
  );
}

export default BussinessAnalytics;
