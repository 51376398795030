import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { contactUsWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";

export default function JoinHandsWithUs() {

  const dispatch = useDispatch();

  let navigate = useNavigate();
  
  const handleGetInTouch = () => {

    dispatch(contactUsWindowScrollAction(0));
    navigate("/contactUs");
    // window.scroll(900,900 , {behavior: 'smooth',})
    
  }
  return (
    <Container maxWidth="xl">
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={3}>
        <Grid
          item
          lg={6}
          md={10}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Typography variant="t52_700">
            We just want to have the best people working for us.
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent={"center"}>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_400_L24">
                We pride ourselves on attracting, developing and engaging an
                unconventional mix of talent that enables us to be the think
                different, act different, deliver kind of agency we are today.
              </Typography>
            </Grid>

            <Grid item lg={4} md={4} sm={4} xs={12} justifyContent={"center"}>
              <Button
                sx={{
                  backgroundColor: "common.yellow",
                  border: `1.5px solid #FFB800`,
                  color: "common.white",
                  width: "100%",
                  ":hover": { color: "common.yellow" },
                }}
                onClick={() => handleGetInTouch()}>
                <Typography>Join Hands with us</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
