import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const FileUploadButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[400],
  background: "#F9FAFB",
  boxShadow: "none",
  justifyContent: "space-between",
  height: "42px",
  border: "1px solid rgba(0, 0, 0, 0.5)",

  "&:hover": {
    backgroundColor: "#F9FAFB",
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.5)",
  },
}));

export default FileUploadButton;
