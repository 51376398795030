import { Grid, Typography } from "@mui/material";
import React from "react";
import GridTempTextImage2 from "./gridTemp2";
import Laptop from "../../asset/lap6.svg"


export default function WholesaleDistributionManagement() {
  const title = "Wholesale Distribution Management";
  const subTitle = "Mobile Application Development";
  const benefit =
    "Improved Distribution Agent Experience, Calling Cost Savings, FTE Resource Reduction, Increase in Sales, Reduction in Delivery Duration";
  const launch = "10 Weeks";
  const project = " Maintaining for past 7 years";
  const button = "View Details";

  const content = [
    {
      content:
        "Wholesale Distribution Management Mobile Application is used by a distribution agency’s sales & collection agents as well as back office agents.",
    },
    {
      content:
        "This hybrid mobile application helps with managing inventory, payments, goods dispatch and delivery, analytics on sales etc.,",
    },
    {
      content: "React",
    },
  ];
  return (
    <GridTempTextImage2
      title={title}
      content={content}
      benefit={benefit}
      launch={launch}
      project={project}
      subTitle={subTitle}
      button={button}
      image={Laptop}
    />
  );
}
