import { Grid, Typography } from "@mui/material";
import React from "react";
import GridTempTextImage2 from "./gridTemp2";
import Laptop from "../../asset/lap1.svg";

export default function ShippingRequirementsManagement() {
  const title = "Shipping Requirements Management";
  const subTitle = "Application Development";
  const benefit = "Truck Tracking Tool";
  const launch = "abc";
  const project = "Truck Tracking Tool";
  const button = "View Details";
  const pageUrl = "/projects/shippingRequirements";

  const content = [
    {
      content:
        "Customs Duty Management application is used by a multinational consumer goods corporation that imports raw materials from Asia and Europe into the UK.",
    },
    {
      content:
        "This web application (integrated with SAP) helps the importer in benefiting from the HMRC customs import duty exemption for all raw materials imported from within Europe.",
    },
    {
      content: "Java/J2EE, ABAP, Oracle, Axway MFT",
    },
  ];
  return (
    <GridTempTextImage2
      title={title}
      content={content}
      benefit={benefit}
      launch={launch}
      project={project}
      subTitle={subTitle}
      button={button}
      image={Laptop}
      pageUrl={pageUrl}
      type="view"
    />
  );
}
