import { Grid } from "@mui/material";
import React from "react";
import CardTemp from "../cardTemp";
import SkillCard_1 from "../../asset/skillCard_1.svg";
import SkillCard_2 from "../../asset/skillCard_2.svg";
import SkillCard_3 from "../../asset/skillCard_3.svg";
import SkillCard_4 from "../../asset/skillCard_4.svg";

function Cards() {
  return (
    <Grid
      container
      spacing={10}
      display="flex"
      alignItems="center"
      justifyContent="center"
      p="30px">
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        display="flex"
        justifyContent="flex-end">
        <CardTemp
          img={SkillCard_1}
          title={"Ongoing Supports"}
          content={
            "Bug fixing, Service Request fulfilment, Incident Management and Problem Management"
          }
        />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        display="flex"
        justifyContent="flex-start">
        <CardTemp
          img={SkillCard_2}
          title="Adaptive Enhancements"
          content={
            "Modifications to support changes in business or technical requirements"
          }
        />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        display="flex"
        justifyContent="flex-end">
        <CardTemp
          img={SkillCard_3}
          title={"Perfective Upgrades"}
          content={"Adding New Functionalities or Features"}
        />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        display="flex"
        justifyContent="flex-start">
        <CardTemp
          img={SkillCard_4}
          title={"Technical Improvements"}
          content={"Optimisation and Restructuring or Rewriting"}
        />
      </Grid>
    </Grid>
  );
}

export default Cards;
