import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Card, Grid } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useState } from "react";
import OSMap from "./osMap";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MapTabs() {
  const [coordinates, setCoordinates] = useState([
    {
      id: 1,
      latitude: 12.990955047466512,
      longitude: 80.21827047238786,
      country: "India",
      company_name: "VeeTee Technologies",
      company_address:
        "65, 1st Floor, Sankaran Avenue, Radhakrishnan Street, Velachery 100ft Road, Chennai - 600 042. TN, India.",
      phone_number: "+91-44-4211 7914",
      mail: "veetee@vttech.in",
    },
    {
      id: 2,
      latitude: 53.469976,
      longitude: -2.1403,
      country: "UK",
      company_name: "VeeTee Technologies",
      company_address: "10 Littlemoss Close, Audenshaw, Manchester, M34 5US",
      phone_number: "+44(0)161 312 1382 ​",
      mail: "veetee@vttech.in",
    },
    {
      id: 3,
      latitude: 36.1716,
      longitude: 155.5828,
      country: "US",
      company_name: "VeeTee Technologies",
      company_address: "US",
      phone_number: "+91-44-4211 7914",
      mail: "veetee@vttech.in",
    },
  ]);
  const [locationCenter, setLocationCenter] = useState([
    12.990955047466512, 80.21827047238786,
  ]);
  console.log("locationCenter", locationCenter);
  const [value, setValue] = React.useState(0);
  const mapRef = React.useRef();
  const location = [];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid style={{ position: "relative" }}>
      <Grid style={{}}>
        <OSMap
          coordinates={coordinates}
          setCoordinates={setCoordinates}
          locationCenter={locationCenter}
        />
      </Grid>

      <Grid
        style={{
          // border: "2px solid red",
          position: "absolute",
          top: "40%",
          right: "3%",
        }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "relative",
            maxHeight: { xs: 10, sm: 260, md: 280, lg: 320 },
            minHeight: { xs: 5, sm: 250, md: 270, lg: 310 },
            maxWidth: { xs: 360, sm: 460, md: 420, lg: 520 },
            minWidth: { xs: 350, sm: 450, md: 410, lg: 510 },
            zIndex: 100,
            // border: "2px solid black",
            // right: "45%",
            // top: "70%",
          }}>
          <Card
            sx={{
              bgcolor: "common.orange",
              color: "common.white",
              p: "25px",
              width: "100%",
              m: "20px",
              borderRadius: 2,
              position: "absolute",
            }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example">
                  <Tab
                    onClick={() => {
                      setLocationCenter([
                        12.990955047466512, 80.21827047238786,
                      ]);
                      // showLocation();
                    }}
                    label={
                      <Typography variant="t24_500" color="common.white">
                        {"India"}
                      </Typography>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    onClick={() => {
                      setLocationCenter([53.469976, -2.1403]);
                      // showLocation();
                    }}
                    label={
                      <Typography variant="t24_500" color="common.white">
                        {"United Kingdom"}
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    onClick={() => {
                      setLocationCenter([36.1716, 155.5828]);
                      // showLocation();
                    }}
                    label={
                      <Typography variant="t24_500" color="common.white">
                        {"United States"}
                      </Typography>
                    }
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid container spacing={3} display="flex" flexDirection="row">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="t24_700">
                      {"VeeTee Technologies"}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="t20_500">
                      {
                        "65, 1st Floor, Sankaran Avenue, Radhakrishnan Street, Velachery 100ft Road, Chennai - 600 042. TN, India."
                      }{" "}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Box direction="row" display="flex" alignItems="center">
                      <LocalPhoneIcon size="small" color="common.black" />
                      <Typography variant="t16_500" pl="10px">
                        {"+91-44-4211 7914"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={12} sm={12} xs={12}>
                    <Box direction="row" display="flex" alignItems="center">
                      <EmailOutlinedIcon size="small" color="common.black" />
                      <Typography variant="t16_500" pl="10px">
                        {"veetee@vttech.in"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={3} display="flex" flexDirection="row">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="t24_700">
                      {"VeeTee Technologies UK"}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="t20_500">
                      {"10 Littlemoss Close, Audenshaw, Manchester, M34 5US"}{" "}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Box direction="row" display="flex" alignItems="center">
                      <LocalPhoneIcon size="small" color="common.black" />
                      <Typography variant="t16_500" pl="10px">
                        {"+44(0)161 312 1382 ​"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={12} sm={12} xs={12}>
                    <Box direction="row" display="flex" alignItems="center">
                      <EmailOutlinedIcon size="small" color="common.black" />
                      <Typography variant="t16_500" pl="10px">
                        {"veetee@vttech.in"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid container spacing={3} display="flex" flexDirection="row">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="t24_700">
                      {"VeeTee Technologies US"}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="t20_500">
                      {
                        "65, 1st Floor, Sankaran Avenue, Radhakrishnan Street, Velachery 100ft Road, Chennai - 600 042. TN, India."
                      }{" "}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Box direction="row" display="flex" alignItems="center">
                      <LocalPhoneIcon size="small" color="common.black" />
                      <Typography variant="t16_500" pl="10px">
                        {"+91-44-4211 7914"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={12} sm={12} xs={12}>
                    <Box direction="row" display="flex" alignItems="center">
                      <EmailOutlinedIcon size="small" color="common.black" />
                      <Typography variant="t16_500" pl="10px">
                        {"veetee@vttech.in"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </Card>
          {/* <OSMap locationCenter={locationCenter} coordinates={coordinates} /> */}
        </Box>
      </Grid>
    </Grid>
  );
}
