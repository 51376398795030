import { Container, Grid, Typography } from "@mui/material";
import React from "react";

function History() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={5} display="flex" alignItems="center">
        <Grid item lg={12}>
          {" "}
          <Typography variant="t52_700_L60">{"Our History"}</Typography>
        </Grid>
        <Grid item lg={3}></Grid>
        <Grid item lg={9}>
          <Typography variant="t18_500">
            {
              "Established in 2007, VeeTee Technologies Private Limited is a leading IT services provider. We specialize in Software Development services. We enable organizations to leverage and add value to their business operations with fullest use of latest technologies. With a wide spectrum of technology offerings, broad experience, modern infrastructure, best practices and quality, we are in strong position to provide offshore and onsite IT and IT-enabled services. We analyze clients’ needs with due diligence and offer appropriate IT solutions to address the requirements."
            }
          </Typography>
        </Grid>
        <Grid item lg={3}></Grid>
        <Grid item lg={9}>
          {" "}
          <Typography align="left" variant="t18_500">
            {
              "We create software applications for PCs, web, and mobile. We build cost-effective solutions by extensively using open source technologies, and leveraging cloud computing for maximum value. We prefer agile development methodology for faster, and controlled construction and delivery of projects."
            }
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default History;
