import { Box, Card, Typography } from "@mui/material";
import React from "react";

function HomeCardsTemp(props) {
  const { img, title, content } = props;
  return (
    <Card
      sx={{
        height: "80%",
        border: (theme) => `1px solid ${theme.palette.grey[200]}`,
        // boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
        p: "20px",
        borderRadius: "10px",
        backgroundColor: "common.white",
      }}>
      <Box display="flex" height="100%" width="100%" alignItems="center">
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 75, sm: 75, md: 75 },
            minHeight: { xs: 70, sm: 70, md: 70 },
            maxWidth: { xs: 75, sm: 75, md: 75 },
            minWidth: { xs: 70, sm: 70, md: 70 },
            // border: "2px solid black",
            // pr: "20px",
          }}>
          <img
            src={img}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
        <Box pl="15px">
          <Typography variant="t18_700">{title}</Typography>
          <Box display="flex" mt="4px" alignItems="center">
            <Typography variant="t12_400">{content}</Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default HomeCardsTemp;
