import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ServiceTransition from "../../asset/serviceTransition.svg";

function ServiceApproach() {
  return (
    <Grid
      container
      spacing={7}
      display="flex"
      justifyContent="center"
      alignItems="center">
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Typography variant="t52_700">Our Service Approach</Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Typography>
          At Booming, we strive to be agile in service transition to increase
          your speed to market. Below is our framework combining agile ways of
          working within IT Service Management.
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 360, sm: 560, md: 560, lg: 820 },
            minHeight: { xs: 350, sm: 550, md: 550, lg: 810 },
            maxWidth: { xs: 360, sm: 710, md: 910, lg: 1320 },
            minWidth: { xs: 350, sm: 700, md: 900, lg: 1310 },
            // border: "2px solid black",,
          }}>
          <img
            src={ServiceTransition}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default ServiceApproach;
