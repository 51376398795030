import React from "react";
import GridTempTextImage from "../gridTempTextImage";
import SkillsImg_2 from "../../asset/skillsImg_2.svg";

function SoftwareDev() {
  const title = "Software Development";

  const content = [
    {
      content:
        "Fulfilling your unique needs with our custom web application solutions.",
    },
    {
      content:
        "Enhancing our solutions by integrating with your ERP solutions like SAP.",
    },
    {
      content:
        "Accelerating your business processes with Business Process Automation.",
    },
    {
      content:
        "Ensuring scalability of applications we build by embracing cloud technologies.",
    },
    {
      content:
        "Saving your cost by leveraging cost efficient open source technologies.",
    },
  ];
  return (
    <GridTempTextImage
      title={title}
      image={SkillsImg_2}
      content={content}
      type={"skills"}
    />
  );
}

export default SoftwareDev;
