import React from "react";
import CPSTTemp from "../projects/tempCpst";
import FeaturesBenifitsTemp from "../projects/tempFeaturesBenifits";

export default function Benefits() {
  const featuresBenifitsContentLeft = [
    {
      featuresBenifitsContentLeft:
        "Setup data cubes so that sales plan operational reports can be generated.",
    },
    {
      featuresBenifitsContentLeft:
        "Created corporate reports so users can generate self serve reports.",
    },
  ];

  const featuresBenifitsContentRight = [
    {
      featuresBenifitsContentRight:
        "Visualizations (bar, line, ghantt, bubble , Pie, maps, hybrid charts etc) were created so that operational and business decisions were made easier.",
    },
    {
      featuresBenifitsContentRight:
        "Conducted Training for users so that the users can generate self serve reports.",
    },
  ];

  return (
    <FeaturesBenifitsTemp
      featuresBenifitsContentLeft={featuresBenifitsContentLeft}
      featuresBenifitsContentRight={featuresBenifitsContentRight}
    />
  );
}
