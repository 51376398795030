import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import Mission from "../../components/aboutUs/mission";
import EndTemp from "../../components/aboutUs/endTemp";
import History from "../../components/aboutUs/history";
import Images from "../../components/aboutUs/images";
import Strength from "../../components/aboutUs/strength";
import Us from "../../components/aboutUs/us";
import Intro from "../../components/aboutUs/intro";
import ResponsiveAppBar from "../../layout/appBar";
import LogoOrange from "../../asset/logoOrange.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { contactUsWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";
// import { TabTitle } from "../../utils/commonFunction";

function AboutUs() {
  // TabTitle("VEETEE - About Us");
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const handleGetInTouch = () => {
    // // console.log("Dalapathy");
    navigate("/contactUs");
    // window.scroll(900,900)
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
    dispatch(contactUsWindowScrollAction(650));

  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Grid container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VEETEE - About Us</title>
        <link rel="canonical" href="http://localhost:3000/aboutUs" />
        <meta name='title' content={"title"} />
        <meta name='description' content={"description"} />
      </Helmet>
      <ResponsiveAppBar
        navBarBgColor={"common.black"}
        ButtonBgcolor={"common.orange"}
        logo={LogoOrange}
        ButtonBorder={"#FD7E3B"}
        bText={"common.white"}
        text={"common.white"}
        hoverColor={"#FD7E3B"}
        hBorderColor={"#FD7E3B"}
        handleGetInTouch={handleGetInTouch}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        bgcolor="common.black"
        // maxHeight="120%"
        color="common.white">
        <Intro />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Mission />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <History />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        backgroundColor="common.orange"
        className="appMargin">
        <Us />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Images />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Strength />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        backgroundColor="common.lightOrange"
        className="appMargin">
        <EndTemp />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default AboutUs;
