import React from "react";
import CPSTTemp from "../projects/tempCpst";
import FeaturesBenifitsTemp from "../projects/tempFeaturesBenifits";

export default function ShippingRequirementsFB() {
  const featuresBenifitsContentLeft = [
    {
      featuresBenifitsContentLeft:
        "Add/Modify Customer Shipping Requirements (e.g. delivery temperature, delivery time, carrier equipment, Halal requirmenets, Documentation requirements, Equipment wash out requirements, Driver Protection requirements, Seal requirements, Weigh tickets, Tank Valves)",
    },
    {
      featuresBenifitsContentLeft: "Manage Product Temperatures",
    },
    {
      featuresBenifitsContentLeft: "Manage Plant Comments",
    },
  ];

  const featuresBenifitsContentRight = [
    {
      featuresBenifitsContentRight:
        "Manage Picklist so configuration items can be managed by business users without software development.",
    },
    {
      featuresBenifitsContentRight: "User Administration",
    },
  ];

  return (
    <FeaturesBenifitsTemp
      featuresBenifitsContentLeft={featuresBenifitsContentLeft}
      featuresBenifitsContentRight={featuresBenifitsContentRight}
    />
  );
}
