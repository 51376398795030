import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import DataAnalytics from "../../components/services/dataAnalytics";
import WebAppDev from "../../components/services/webAppDevelopment";
import WebScrap from "../../components/services/webScraping";
import UiAndUx from "../../components/services/uiAndux";
import ServiceApproach from "../../components/services/serviceApproach";
import ServiceStandard from "../../components/services/serviceStandard";
import Intro from "../../components/services/intro";
import Service from "../../components/services/service";
import ResponsiveAppBar from "../../layout/appBar";
import LogoWhite from "../../asset/logoWhite.png";
import We from "../../components/services/we";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { contactUsWindowScrollAction, serviceWindowScrollAction } from "../../redux/actions/action";
// import { TabTitle } from "../../utils/commonFunction";

function Services() {

  const dispatch = useDispatch();

  const {
    AdminReducer: { service_window },
  } = useSelector((state) => state);
  // TabTitle('VEETEE - Services')
  let navigate = useNavigate();
  const handleGetInTouch = () => {
    // console.log("Dalapathy");
    navigate("/contactUs");
    // window.scroll(900,900)
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
    dispatch(contactUsWindowScrollAction(650));

  };

  useEffect(() => {

    window.scrollTo({
      top: service_window,
      behavior: "smooth",
    });

    // dispatch(serviceWindowScrollAction(0));
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: service_window,
      behavior: "smooth",
    });

  }, [service_window]);

  console.log('service_window', service_window);

  return (
    <Grid container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VEETEE - Services</title>
        <link rel="canonical" href="http://localhost:3000/service" />
        <meta name='title' content={"title"} />
        <meta name='description' content={"description"} />
      </Helmet>
      <ResponsiveAppBar
        navBarBgColor={"common.orange"}
        ButtonBgcolor={"transparent"}
        logo={LogoWhite}
        ButtonBorder="whitesmoke"
        bText="common.white"
        text={"common.white"}
        hoverColor={"common.white"}
        hBorderColor={"#FFFFFF"}
        handleGetInTouch={handleGetInTouch}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        maxHeight="50vh"
        bgcolor="common.orange">
        <Service />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Intro type="mobile" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <We />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <WebAppDev />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <WebScrap />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <DataAnalytics />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <UiAndUx />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ServiceStandard />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ServiceApproach />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Services;
