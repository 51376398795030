import { OpenalertActions } from "./alert_actions";
// import axios from 'axios';
import {
  Create_label,
  Update_label,
  Delete_label,
  Exist_label,
  mail_send,
  Cancel_label,
} from "../utils/content";

export const ListLoad = (setModalTypeHandler, setLoaderStatusHandler) => {
  if (setModalTypeHandler && setLoaderStatusHandler) {
    setLoaderStatusHandler(true);
  }
};

export const FailLoad = async (
  setModalTypeHandler,
  setLoaderStatusHandler,
  setModalStatusHandler
) => {
  if (setModalTypeHandler && setLoaderStatusHandler) {
    setLoaderStatusHandler(false);
  }
  if (setModalStatusHandler) {
    setModalStatusHandler(false);
  }
};

export const UpdateAlert = async (dispatch) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: Update_label, severity: "success" }));
  }
};

export const CreateAlert = async (dispatch) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: Create_label, severity: "success" }));
  }
};

export const ExistAlert = async (dispatch) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: Exist_label, severity: "error" }));
  }
};

export const DeleteAlert = async (dispatch) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: Delete_label, severity: "success" }));
  }
};

export const ErrorAlert = async (dispatch, err) => {
  console.log("erralert", err);
  if (dispatch) {
    dispatch(OpenalertActions({ msg: err.message, severity: "error" }));
  }
};

export const Password = async (dispatch) => {
  if (dispatch) {
    dispatch(
      OpenalertActions({ msg: "Enter Valid Old Password", severity: "error" })
    );
  }
};
export const Passwordsuccess = async (dispatch) => {
  if (dispatch) {
    dispatch(
      OpenalertActions({
        msg: "Password Change Successfully",
        severity: "error",
      })
    );
  }
};

export const successmsg = async (sample) => {
  if (sample) {
    sample(false);
  }
};

export const errormsg = async (sample) => {
  if (sample) {
    sample(true);
  }
};

export const ProductDeleteAlert = async (dispatch, msg) => {
  console.log("ProductDeleteAlert", msg);
  if (dispatch) {
    await dispatch(OpenalertActions({ msg, severity: "warning" }));
  }
};

export const MailAlert = async (dispatch) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: mail_send, severity: "success" }));
  }
};

export const CancelAlert = async (dispatch) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: Cancel_label, severity: "success" }));
  }
};
export const CannotDeleteAlert = async (dispatch, res) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: res.message, severity: "warning" }));
  }
};

export const StockReconcilateMatchedAlert = async (dispatch) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: "Matched", severity: "success" }));
  }
};

export const StockReconcilateMismatchedAlert = async (dispatch) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: "Mismatched", severity: "warning" }));
  }
};
export const inValidFileAlert = async (dispatch, res) => {
  if (dispatch) {
    dispatch(OpenalertActions({ msg: res.message, severity: "warning" }));
  }
};
