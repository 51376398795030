import { Grid, Typography } from "@mui/material";
import React from "react";
import GridTempTextImage2 from "./gridTemp2";
import Laptop from "../../asset/mobile.svg"


export default function Maps() {
  const title = "MAPS - Mobile Attendance and Payroll System.";
  const subTitle = "Mobile Application Development";
  const benefit =
    "SAP License Cost Saving, Restricting 3rd Party Access to SAP ERPs";
  const launch = "6 Weeks";
  const project = "Maintaining for past 7 years";
  const button = "View Details";

  const content = [
    {
      content:
        "Shipping Requirements Management application is used by a chemical manufacturing unit of a multinational consumer goods corporation.  ",
    },
    {
      content:
        "This web application helps the manufacturer to record and manage shipping instructions for a massive range of products that are to be followed by 3rd parties involved in shipping.",
    },
    {
      content: "Java/J2EE, Oracle",
    },
  ];
  return (
    <GridTempTextImage2
      title={title}
      content={content}
      benefit={benefit}
      launch={launch}
      project={project}
      subTitle={subTitle}
      button={button}
      image={Laptop}
    />
  );
}
