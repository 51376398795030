import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import SkillsImg_1 from "../../asset/skillsImg_1.svg";

function ServiceR() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={5}>
        {" "}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={5}>
            {" "}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t32_700">
                {
                  "To Ensure Service Reliability & Rapid Service Delivery To Your Customers."
                }
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_400">
                {
                  "Leo vel orci porta non pulvinar neque. Lobortis mattis aliquam faucibus purus in massa tempor. Hac habitasse platea dictumst quisque. Bibendum at varius vel pharetra vel turpis nunc eget. "
                }
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_400">
                {
                  "Id diam maecenas ultricies mi eget mauris. Faucibus interdum posuere lorem ipsum dolor sit amet consectetur. "
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              position: "relative",
              maxHeight: { xs: 90, sm: 260, md: 360, lg: 360 },
              minHeight: { xs: 80, sm: 250, md: 350, lg: 350 },
              maxWidth: { xs: 260, sm: 560, md: 460, lg: 620 },
              minWidth: { xs: 250, sm: 550, md: 450, lg: 610 },
              // border: "2px solid black",,
            }}>
            <img
              src={SkillsImg_1}
              alt={"NoImage"}
              style={{
                height: "70%",
                width: "70%",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ServiceR;
