import React from "react";
import CPSTTemp from "../projects/tempCpst";

export default function CustomDutyCpst() {
  const clientName = "Customs Duty Management";

  const technologyContent =
    "C++, SQK Server";

    const projectContent = [
      {
        projectContent:
          "Supply Chain Defect Management application is used by a multinational consumer goods manufacturer that has a supply chain covering Europe, North America and Asia Pacific",
      },
      {
        projectContent:
          "This web application helps the manufacturer in preventing and managing defects that occur in their unique supply chain process.",
      },
      {
        projectContent:
          "Java/J2EE, Oracle",
      },
    ];
  const servicesContent = "Application Development";

  return (
    <CPSTTemp
      clientName={clientName}
      technologyContent={technologyContent}
      projectContent={projectContent}
      servicesContent={servicesContent}
    />
  );
}
