import http from "../http-common";

class Service {
  contactEmail(data) {
    console.log("service", data);
    return http.post(
      `/admin/contact/${data.firstName}/${data.lastName}/${data.email}/${data.number}/${data.msg}`,
      data
    );
  }
  careerEmail(data) {
    return http.post(
      `/admin/carrer/${data.firstName}/${data.lastName}/${data.email}/${data.number}/${data.dob}/${data.currentLocation}/${data.course}/${data.institute}/${data.yearOfPassing}/${data.grade}/${data.resume}`,
      data
    );
  }
  projectEmail(data) {
    return http.post(`/admin/email/${data.email}`, data);
  }
}
export default new Service();
