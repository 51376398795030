import React from "react";
import CPSTTemp from "../projects/tempCpst";
import FeaturesBenifitsTemp from "../projects/tempFeaturesBenifits";

export default function CustomDutyFbB() {
  const featuresBenifitsContentLeft = [
    {
      featuresBenifitsContentLeft:
        "Importing and Maintaining SAP Data so that import of raw materials and plant utilisation data is made available in the web application for analysis.",
    },
    {
      featuresBenifitsContentLeft:
        "Maintaining change logs for audit and reconciliation purposes.",
    },
    {
      featuresBenifitsContentLeft:
        "Master data containing comodity code for raw materials and finished products, per material yield, vendor exclusions, material exclusions, End Use Authorisation, duty rates were maintained so that the data imported from SAP could be converted into meaningful information.",
    },
    {
      featuresBenifitsContentLeft:
        "Maintaining all raw materials imports so that the duty liability can be discharged.",
    },
    {
      featuresBenifitsContentLeft:
        "Freed up a few FTE Resources through automation.",
    },
  ];

  const featuresBenifitsContentRight = [
    {
      featuresBenifitsContentRight:
        "Maintaining different Duty payment receipts payable to German and dutch authorities so that these can be reported to UK HMRC.",
    },
    {
      featuresBenifitsContentRight:
        "Cost avoidance: £12.4 million cost avoidance per year through import duty exemption",
    },
    {
      featuresBenifitsContentRight:
        "Generating Bill of discharge report listing all imports and discharges for each raw material per month so that all those can be submitted to UK HMRC.",
    },
    {
      featuresBenifitsContentRight:
        "Maintaining export shipments so that duty can be paid for these shipments which do not have tax exemption.",
    },
  ];

  return (
    <FeaturesBenifitsTemp
      featuresBenifitsContentLeft={featuresBenifitsContentLeft}
      featuresBenifitsContentRight={featuresBenifitsContentRight}
    />
  );
}
