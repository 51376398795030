import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";

function StrengthCards(props) {
  const { img, title, content } = props;
  return (
    <Card
      sx={{
        ":hover": {
          backgroundColor: "common.orange",
          color: "common.white",
        },
        p: "30px",
        backgroundColor: "common.paper",
        border: 0,
        borderRadius: "16px",
        borderColor: "common.lightGrey",
        color: "common.black",
        // position: "relative",
        // maxHeight: { xs: 260, sm: 460, md: 620 },
        // minHeight: { xs: 250, sm: 450, md: 610 },
        // maxWidth: { xs: 260, sm: 460, md: 620 },
        // minWidth: { xs: 250, sm: 450, md: 610 },
        // // border: "2px solid black",,
      }}>
      <Box
        sx={{
          position: "relative",
          maxHeight: { xs: 260, sm: 310, md: 310 },
          minHeight: { xs: 250, sm: 300, md: 300 },
          maxWidth: { xs: 260, sm: 510, md: 420 },
          minWidth: { xs: 250, sm: 500, md: 410 },
          // // border: "2px solid black",,
        }}>
        <Grid container spacing={3} display="flex" alignItems="center">
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            alignItems="center">
            <img
              src={img}
              alt={"NoImage"}
              style={{
                height: "20%",
                width: "20%",
              }}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={8}
            xs={8}
            display="flex"
            alignItems="center">
            <Typography variant="t24_700_L29">{title}</Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={10}
            sm={6}
            xs={12}
            display="flex"
            alignItems="center">
            <Typography variant="t18_500" align="left">
              {content}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default StrengthCards;
