import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { exploreSolution } from "../../label";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeCardsTemp from "./homeCardsTemp";
import HomeImg3 from "../../asset/homeImg3.png";
import HomeImg3bg from "../../asset/hImg3bg.png";
import HomeImg3Vcircle from "../../asset/hImg3Ellipse1.png";
import HomeImg3Hcircle from "../../asset/hImg3Ellipse2.png";
import HomeImg3Tri from "../../asset/hImg3Tri.png";
import HImg3Browser from "../../asset/hImg3Browser.png";
import Case1 from "../../asset/case1.svg";
import Case2 from "../../asset/case2.svg";
import { ExploreSolutionFunc } from "../../utils/commonFunction";
import { useNavigate } from "react-router-dom";
import { serviceWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";

function WebScraping() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const redirect = () => {
    dispatch(serviceWindowScrollAction(0));

    navigate("/service");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const cardContent = [
    {
      img: Case1,
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
    {
      img: Case2,
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
  ];
  return (
    <Container maxWidth="xl">
      <Grid container display="flex" color="common.white" spacing={7}>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Grid container spacing={5}>
            <Grid item lg={7}>
              <Typography variant="t60_700">{"Web Scraping"}</Typography>
            </Grid>
            <Grid item lg={10}>
              <Typography variant="t18_500">
                {
                  "We are web scraping services providers to generate leads for our clients, amass pertinent information from competitors’ web pages, classifying trends from collected documents, and analyzing unstructured information. We are competent in converting websites into structured data. Complete data extraction- like setting up scrapers, running it, cleansing the data, reviewing the quality of data and ensuring timely delivery of data can be taken up. Our investments are significant in automation, scalability and process efficiency to provide an exceptional service at no additional cost to our customers.  "
                }
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Button
                onClick={redirect}
                sx={{
                  backgroundColor: "transparent",
                  border: `1px solid whitesmoke`,
                  color: "common.white",
                  width: "100%",
                  height: "100%",
                }}>
                {exploreSolution}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center">
          <Box
            sx={{
              position: "relative",
              maxHeight: { xs: 260, sm: 460, md: 620 },
              minHeight: { xs: 250, sm: 450, md: 610 },
              maxWidth: { xs: 260, sm: 460, md: 620 },
              minWidth: { xs: 250, sm: 450, md: 610 },
              // // border: "2px solid black",,
            }}>
            <img
              src={HomeImg3bg}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "80%",
                width: "80%",
                top: "20%",
              }}
            />
            <img
              src={HomeImg3Vcircle}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "12%",
                width: "25%",
                right: "88%",
                top: "20%",
              }}
            />
            <img
              src={HomeImg3Hcircle}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "25%",
                width: "12%",
                top: "20%",
              }}
            />
            <img
              src={HomeImg3Tri}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "20%",
                width: "20%",
                left: "65%",
                top: "15%",
              }}
            />
            <img
              src={HomeImg3}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "100%",
                width: "90%",
              }}
            />
            <img
              src={HImg3Browser}
              alt={"NoImage"}
              style={{
                position: "absolute",
                height: "30%",
                width: "30%",
                top: "75%",
                right: "80%",
              }}
            />
          </Box>
        </Grid>
        {/* <Grid item lg={12}>
        <Grid container spacing={5}>
          <Grid item lg={2}>
            <Typography variant="t32_700">{"Case Studies"}</Typography>
            <Box display={"flex"} flexDirection="row" pt="20px">
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid whitesmoke`,
                  color: "common.white",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `1.5px solid #FD7E3B`,
                  },
                  mr: "10px",
                }}>
                <ArrowBackIcon />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid whitesmoke`,
                  color: "common.white",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `1.5px solid #FD7E3B`,
                  },
                }}>
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </Grid>
          {cardContent.map((m) => (
            <Grid item lg={4} md={6} sm={9} xs={12}>
              <HomeCardsTemp title={m.title} img={m.img} content={m.content} />
            </Grid>
          ))}
        </Grid>
      </Grid> */}
      </Grid>
    </Container>
  );
}

export default WebScraping;
