import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import ServiceLevel from "../../asset/serviceLevel.svg";
import EastIcon from "@mui/icons-material/East";

function ServiceStandard() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={4} display="flex">
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Typography variant="t52_700" align="center">
            {"Our Commitment: Service Standards"}
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Typography variant="t18_400_L28" align="center">
            {"Our commitment to you is the foundation of our services."}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={18} pt="30px">
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display="flex"
          // alignItems="center"
        >
          <Grid container spacing={4} display="flex">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t32_700">{"Service Levels"}</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_400_L28">
                {
                  "We have standards that show you the level of service you should expect from us."
                }
              </Typography>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="flex-end"
              alignItems="center">
              <img
                src={ServiceLevel}
                alt="NoImage"
                style={{ height: "80%", weight: "80%" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={3} display="flex">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t24_400_L56" align="center">
                {"Service Levels 1"}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_400_L28" align="center">
                {
                  "Critical – Incidents that have an immediate and high business impact or reflect that a major part of a business application is unavailable for multiple locations"
                }
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t24_400_L56" align="center">
                {"Service Level 02"}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_400_L28" align="center">
                {
                  "Major – Incidents that have a medium to high business impact, or reflect that a significant portion of business application is degraded"
                }
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t24_400_L56" align="center">
                {"Service Level 03"}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_400_L28" align="center">
                {
                  "Minor – Incidents that have a low business impact. Incidents or System Warnings that might develop into a bigger problem."
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ServiceStandard;
