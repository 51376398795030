import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Main from "../../components/careers/main";
import CareersForm from "../../components/careers/form";
import ResponsiveAppBar from "../../layout/appBar";
import LogoWhite from "../../asset/logoWhite.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { contactUsWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";
// import { TabTitle } from "../../utils/commonFunction";

function Careers() {
  // TabTitle("VEETEE - Careers");
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGetInTouch = () => {
    // console.log("Dalapathy");
    navigate("/contactUs");
    // window.scroll(900,900)
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
    dispatch(contactUsWindowScrollAction(650));

  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Grid container display="flex" flexDirection="column">
      <Helmet>
        <meta charSet="utf-8" />
        <title>VEETEE - Careers</title>
        <link rel="canonical" href="http://localhost:3000/careers" />
        <meta name='title' content={"title"} />
        <meta name='description' content={"description"} />
      </Helmet>
      <ResponsiveAppBar
        navBarBgColor={"common.grey"}
        ButtonBgcolor={"common.orange"}
        logo={LogoWhite}
        ButtonBorder={"transparent"}
        bText={"common.white"}
        text={"common.white"}
        hoverColor={"common.white"}
        hBorderColor={"#FFFFFF"}
        handleGetInTouch={handleGetInTouch}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        // p="2% 7%"
        className="appMargin"
        sx={{
          backgroundColor: "common.grey",
        }}
        maxHeight="75vh">
        <Main />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <CareersForm />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Careers;
