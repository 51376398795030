import { Container, Grid, Typography } from "@mui/material";
import React from "react";

export default function ChallengeSolutionTemp(props) {
  const { challengeContent, solutionContent } = props;
  return (
    <Container maxWidth="xl">
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        alignContent={"center"}
        spacing={3}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} pb="20px">
              <Typography variant="t52_700">Challenge</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} pb="15px">
              {challengeContent.map((m) => (
                <>
                  <Grid container>
                    <Grid item lg={11} md={11} sm={11} xs={11} pb="20px">
                      <Typography variant="t18_400_L22">
                        {m.challengeContent}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} pb="20px">
              <Typography variant="t52_700">Solution</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} pb="15px">
              {solutionContent.map((m) => (
                <>
                  <Grid container>
                    <Grid item lg={11} md={11} sm={11} xs={11} pb="20px">
                      <Typography variant="t18_400_L22">
                        {m.solutionContent}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
