import React from "react";
import ChallengeSolutionTemp from "../projects/tempChallengeSolution";
import GridTempTextImage1 from "../projects/gridTemp1";

export default function ChallengeSolutio() {
  const challengeContent = [
    {
      challengeContent:
        "As 11g became obsolete and was losing vendor support, the database was at the risk of running on an unsupported version",
    },
  ];
  const solutionContent = [
    {
      solutionContent:
        "Upgraded 5 oracle database instances with least interruption for users",
    },
  ];

  return (
    <ChallengeSolutionTemp
      challengeContent={challengeContent}
      solutionContent={solutionContent}
    />
  );
}
