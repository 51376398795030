import React from "react";
import GridTempImageText from "../gridTempImageText";
import uiux from "../../asset/uiux.svg";

function DataAnalytics() {
  const title = "UI & UX";

  const content = [
    {
      content: "Drive your business growth with data scraped from the web.",
    },
    {
      content:
        "Attract new businesses on first page of search engine results by understanding and using the right keywords used by your customers.",
    },
    {
      content:
        "Empower your machine learning with big data by fetching huge variety, velocity and volume of data from the web.",
    },
    {
      content:
        "Improve your lead generation by gathering contact details of businesses or individuals from the web.",
    },
    {
      content:
        "Monitor your brand reputation and brand perceptions by fetching data about your brand from the web.",
    },
    {
      content:
        "Understand how your customers feel about your products or services by monitoring what they wrtie about you on the web.",
    },
  ];
  return <GridTempImageText title={title} image={uiux} content={content} />;
}

export default DataAnalytics;
