import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import tableau from "../../asset/oracleDiscoverer/Tableau.svg";
import oracleBB from "../../asset/oracleDiscoverer/Oracle-BB.svg";

export default function ToolsAndTechnologies() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={5} display="flex" flexDirection="row">
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center">
          {" "}
          <Typography
            variant="t40_700"
            sx={{
              color: "common.black",
            }}>
            Tools and Technologies
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} display="flex">
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box
                sx={{
                  position: "relative",
                  maxHeight: { xs: 110, sm: 110, md: 110, lg: 110 },
                  minHeight: { xs: 100, sm: 100, md: 100, lg: 100 },
                  maxWidth: { xs: 350, sm: 360, md: 420, lg: 620 },
                  minWidth: { xs: 340, sm: 350, md: 410, lg: 610 },
                  // border: "2px solid black",
                }}>
                <img
                  src={tableau}
                  alt=""
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box
                sx={{
                  position: "relative",
                  maxHeight: { xs: 110, sm: 110, md: 110, lg: 110 },
                  minHeight: { xs: 100, sm: 100, md: 100, lg: 100 },
                  maxWidth: { xs: 350, sm: 360, md: 420, lg: 620 },
                  minWidth: { xs: 340, sm: 350, md: 410, lg: 610 },
                  // border: "2px solid black",
                }}>
                <img
                  src={oracleBB}
                  alt=""
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center">
          <Grid container spacing={7} display="flex" justifyContent="center">
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyCotent="center">
                <Typography
                  variant="t52_700_L70px"
                  sx={{
                    color: "common.yellow",
                  }}>
                  {"7600"}
                </Typography>
                <Typography variant="t16_400">{"Hours of Work"}</Typography>
                <Divider />
              </Box>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyCotent="center">
                <Typography
                  variant="t52_700_L70px"
                  sx={{
                    color: "common.yellow",
                  }}>
                  {"07"}
                </Typography>
                <Typography variant="t16_400">
                  {"Features Developed"}
                </Typography>
                <Divider />
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyCotent="center">
                <Typography
                  variant="t52_700_L70px"
                  sx={{
                    color: "common.yellow",
                  }}>
                  {"02"}
                </Typography>
                <Typography variant="t16_400">
                  {"Technologies Applied"}
                </Typography>
                <Divider />
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyCotent="center">
                <Typography
                  variant="t52_700_L70px"
                  sx={{
                    color: "common.yellow",
                  }}>
                  {"08"}
                </Typography>
                <Typography variant="t16_400">{"Team Members"}</Typography>
                <Divider />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
