import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Booming from "../../asset/Boomings-1.png";
import Industries1 from "../../asset/industries-1.png";
import Health_care from "../../asset/Health_care.webp";
import Consumer_Goods from "../../asset/Consumer_Goods.webp";
import Manufacturing from "../../asset/Manufacturing.webp";
import International_Trade from "../../asset/International_Trade.webp";
import Wholesale_Distribution from "../../asset/Wholesale_Distribution.webp";
import Chemicals from "../../asset/Chemicals.webp";
import Sports from "../../asset/Sports.webp";
import IndustriesTemp from "../industriesTemp";

export default function Industries(props) {
  // const title = "Personal Care";

  const IndustriesContentTop = [
    {
      img: Industries1,
      imgContent: "Personal Care",
    },
    {
      img: Health_care,
      imgContent: "Health Care",
    },
    {
      img: Consumer_Goods,
      imgContent: "Retail & Consumer Goods",
    },
    {
      img: Manufacturing,
      imgContent: "Manufacturing",
    },
    {
      img: International_Trade,
      imgContent: "International Trade",
    },
    {
      img: Wholesale_Distribution,
      imgContent: "Wholesale Distribution",
    },
    {
      img: Chemicals,
      imgContent: "Chemical",
    },
    {
      img: Sports,
      imgContent: "Sports",
    },
  ];

  return (
    <Container maxWidth="xl">
      <IndustriesTemp IndustriesContentTop={IndustriesContentTop} />
    </Container>
  );
}
