import React from "react";
import GridTempTextImage from "../gridTempTextImage";
import DataAnalyticsImg from "../../asset/dataAnalytics.svg";

function DataAnalytics() {
  const title = "Data Analytics";

  const content = [
    {
      content: "Align analytics to data strategy and data governance.",
    },
    {
      content:
        "Improve process of collecting, storing, integrating and leveraging data.",
    },
    {
      content: "Expedite the speed of obtaining real time information.",
    },
    {
      content: "Empower your leadership team with valuable insights (KPIs).",
    },
    {
      content:
        "Obtain insights via charts that are appropriate to visualize the information needed.",
    },
    {
      content:
        "Experience flexibility of visualizing data in the tool of your choice (Tableau / Power BI / OBIEE).",
    },
  ];
  return (
    <GridTempTextImage
      title={title}
      image={DataAnalyticsImg}
      content={content}
    />
  );
}

export default DataAnalytics;
