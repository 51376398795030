import React from "react";
import ChallengeSolutionTemp from "../projects/tempChallengeSolution";
import GridTempTextImage1 from "../projects/gridTemp1";

export default function ShippingRequirementsCs() {
  const challengeContent = [
    {
      challengeContent:
        "Being a multinational consumer goods corporation, the customer imports raw materials from both Asian and European countries.",
    },
    {
      challengeContent:
        "UK HMRC provides customs import duty exemption only for raw materials imported from Europe.",
    },
    {
      challengeContent:
        "Hence, raw materials consumed by each plant need to be tracked to benefit from HMRC Import duty exemption.",
    },
  ];
  const solutionContent = [
    {
      solutionContent:
        "Our experts built a strong basement for a Web-based tool to obtain import & discharge data from SAP ERP..",
    },
    {
      solutionContent:
        "The solution is flexible to manage ever-changing rules and features for discharging tax liability.",
    },
  ];

  return (
    <ChallengeSolutionTemp
      challengeContent={challengeContent}
      solutionContent={solutionContent}
    />
  );
}
