import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { exploreSolution } from "../../label";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeCardsTemp from "./homeCardsTemp";
import HomeImg1 from "../../asset/homeImg99.png";
import HImg1 from "../../asset/hImg1.png";
import HImg1Triangle from "../../asset/hImg1Triangle.png";
import HomeImg1Circle from "../../asset/hImg1Ellipse.png";
import HomeImg1Rect from "../../asset/hImg1Rectangle.png";
import Case1 from "../../asset/case1.svg";
import Case2 from "../../asset/case2.svg";
import Slider from "react-slick";
import CardSlider from "./cardSlider";
import { ExploreSolutionFunc } from "../../utils/commonFunction";
import { useNavigate } from "react-router-dom";
import { serviceWindowScrollAction } from "../../redux/actions/action";
import { useDispatch } from "react-redux";

function Intro() {
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const redirect = () => {
    dispatch(serviceWindowScrollAction(0));

    navigate("/service");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const cardContent = [
    {
      id: 1,
      img: Case1,
      title: "Customs Duty Management",
      content:
        "Customs Duty Management application is used by a multinational consumer goods corporation that imports raw materials from Asia and Europe into the UK.",
    },
    {
      id: 2,
      img: Case2,
      title: "Shipping Execution Management",
      content:
        "Shipping Requirements Management application is used by a chemical manufacturing unit of a multinational consumer goods corporation.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  console.log("cardContent0012", cardContent);
  return (
    <Container maxWidth="xl">
      <Grid container display="flex" color="common.white" spacing={5}>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Grid container spacing={5}>
            <Grid item lg={10}>
              <Typography variant="t60_700">
                {"We assure you to deliver the BEST or NOTHING!"}
              </Typography>
            </Grid>
            <Grid item lg={9}>
              <Typography variant="t18_500">
                {
                  "Veetee Technologies has been breaking new ground with its leading-edge software skill sets. As a systems integrator and technology support system, it has provided intelligent technical solutions and consulting services. It caters to different businesses of various scales for effective heterogeneous information resources and services, anytime and anywhere."
                }
              </Typography>
            </Grid>
            <Grid item lg={4}>
              <Button
                onClick={redirect}
                sx={{
                  backgroundColor: "transparent",
                  border: `1px solid whitesmoke`,
                  color: "common.white",
                  width: "100%",
                  height: "100%",
                }}>
                {exploreSolution}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center">
          <Box
            sx={{
              position: "relative",
              maxHeight: { xs: 260, sm: 460, md: 580 },
              minHeight: { xs: 250, sm: 450, md: 570 },
              maxWidth: { xs: 260, sm: 460, md: 620 },
              minWidth: { xs: 250, sm: 450, md: 610 },
              // border: "2px solid black",
            }}>
            <img
              src={HomeImg1Circle}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "70%",
                height: "70%",
                top: "25%",
                bottom: "0%",
                left: "20%",
              }}
            />
            <img
              src={HomeImg1Rect}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "40%",
                height: "45%",
                top: "20.5%",
                bottom: "0%",
                left: "21%",
                borderRadius: " 0% 0% 0% 0%",
              }}
            />

            <img
              src={HomeImg1}
              alt={"NoImage"}
              style={{
                position: "absolute",
                width: "95%",
                height: "85vh",
                bottom: "4.9%",
                right: "3.8%",
              }}
            />
            <img
              src={HImg1Triangle}
              alt="..."
              style={{
                position: "absolute",
                width: "20%",
                height: "20%",
                top: "81%",
                bottom: "0%",
                left: "75.5%",
              }}
            />
            <img
              src={HImg1}
              alt="..."
              style={{
                position: "absolute",
                width: "30%",
                height: "30%",
                top: "74%",
                bottom: "0%",
                right: "57%",
              }}
            />
          </Box>
        </Grid>
        {/* <Grid item lg={12}>
          <Grid container spacing={5}>
            <Grid item lg={2}>
              <Typography variant="t32_700">{"Case Studies"}</Typography>
              <Box display={"flex"} flexDirection="row" pt="20px">
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    border: `1.5px solid whitesmoke`,
                    color: "common.white",
                    width: "20%",
                    height: "20%",
                    borderRadius: "8px",
                    ":hover": {
                      backgroundColor: "common.orange",
                      border: `1.5px solid #FD7E3B`,
                    },
                    mr: "10px",
                  }}>
                  <ArrowBackIcon />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    border: `1.5px solid whitesmoke`,
                    color: "common.white",
                    width: "20%",
                    height: "20%",
                    borderRadius: "8px",
                    ":hover": {
                      backgroundColor: "common.orange",
                      border: `1.5px solid #FD7E3B`,
                    },
                  }}>
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </Grid>
            {cardContent.map((m) => (
              <Grid item lg={4} md={6} sm={9} xs={12}>
                <HomeCardsTemp
                  title={m.title}
                  img={m.img}
                  content={m.content}
                />
              </Grid>
            ))}
          </Grid>
        </Grid> */}
      </Grid>
    </Container>
  );
}

export default Intro;
