import {
  Autocomplete,
  Button,
  Container,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { reset_bt, submit_bt } from "../../label";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileUploadButton from "../overrides/fileUploadButton";
import { CareerEmailAction } from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Dayjs } from "dayjs";
import { emailValidation, phoneValidation } from "../../utils/regex";
import Captcha from "../../utils/captcha";
import ReCAPTCHA from "react-google-recaptcha";

function CareersForm() {
  const [date, setDate] = React.useState(new Date());
  const dispatch = useDispatch();

  const {
    AdminReducer: { carrer },
  } = useSelector((state) => state);

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const [formValue, setFormValues] = useState({
    firstName: null,
    lastName: null,
    email: null,
    number: null,
    dob: null,
    currentLocation: null,
    course: null,
    institute: null,
    yearOfPassing: null,
    grade: null,
    resume: null,
  });

  const [fileName, setImageName] = useState("");

  const [formErrors, setFormErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    number: null,
    dob: null,
    currentLocation: null,
    course: null,
    institute: null,
    yearOfPassing: null,
    grade: null,
    resume: null,
  });

  const [requiredFields] = useState([
    "firstName",
    "lastName",
    "email",
    "number",
    "dob",
    "currentLocation",
    "course",
    "institute",
    "yearOfPassing",
    "grade",
    "resume",
  ]);

  const [validRegex, setValidRegex] = useState({
    email: false,
    phone_number: false,
  });

  const [regex] = useState({});

  const Course = [
    { id: 1, course: "BSC" },
    { id: 2, course: "MSC" },
    { id: 3, course: "BCA" },
    { id: 4, course: "MCA" },
    { id: 5, course: "BTECH" },
    { id: 6, course: "MTECH" },
    { id: 7, course: "BE" },
    { id: 8, course: "ME" },
  ];

  const year = [
    { id: 1, int: "2017" },
    { id: 2, int: "2018" },
    { id: 3, int: "2019" },
    { id: 4, int: "2020" },
    { id: 5, int: "2021" },
    { id: 6, int: "2022" },
    { id: 7, int: "2023" },
  ];

  const onFileChange = (e) => {
    if (e) {
      var file = e.target.files[0];
      var name = e.target.files[0].name;
      setImageName(name);
      var reader = new FileReader();
      reader.onloadend = function () {
        setFormValues({ ...formValue, resume: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDateChange = async (date) => {
    const dateVal = date;
    setDate(dateVal);
    let convertedDate = moment(dateVal, "year", "month", "day").format(
      "yyyy-MM-DD"
    );
    console.log("date", convertedDate, date, dateVal);
    // await props.listSalesDateAction(convertedDate);
  };

  const validationHandler = (name, value) => {
    if (!Object.keys(formErrors).includes(name)) return;

    if (
      requiredFields.includes(name) &&
      (value === null ||
        value === "null" ||
        value === "" ||
        value === false ||
        (Object.keys(value) && value.value === null))
    ) {
      setFormErrors({
        ...formErrors,
        [name]: capitalize(name) + " is Required!",
      });
    } else if (regex[name]) {
      if (!regex[name].test(value)) {
        setFormErrors({
          ...formErrors,
          [name]: capitalize(name) + " is Invalid!",
        });
      } else if (name === "email") {
        if (emailValidation(value) !== true) {
          setValidRegex({ ...validRegex, email: false });
          setFormErrors({
            ...formErrors,
            [name]: capitalize(name) + " is Invalid!",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [name]: null,
          });
          setValidRegex({ ...validRegex, email: true });
        }
      } else if (name === "phoneNumber") {
        console.log("ki", name);
        if (phoneValidation(value) !== true) {
          console.log("jkh", phoneValidation(value));
          setValidRegex({ ...validRegex, phone_number: false });
          setFormErrors({
            ...formErrors,
            [name]: capitalize(name) + " is Invalid!",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [name]: null,
          });
          setValidRegex({ ...validRegex, phoneNumber: true });
        }
      } else {
        setFormErrors({
          ...formErrors,
          [name]: null,
        });
      }
    } else {
      setFormErrors({
        ...formErrors,
        [name]: null,
      });
    }
  };

  const setStateHandler = async (name, value) => {
    let formObj = {};

    formObj = {
      ...formValue,
      [name]: value === "" ? null : value,
    };

    await setFormValues(formObj);
    validationHandler(name, value);
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleChange = async (e) => {
    let { name, value } = e.target;
    setStateHandler(name, value);
    console.log("form change", formValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;
    let formErrorsObj = { ...formErrors };
    await Object.keys(formValue).map((key, i) => {
      if (
        requiredFields.includes(key) &&
        (formValue[key] === null || formValue[key] === "")
      ) {
        isValid = false;
        formErrorsObj[key] = capitalize(key) + " is Required!";
      } else if (regex[key]) {
        if (!regex[key].test(formValue[key])) {
          isValid = false;
          formErrorsObj[key] = capitalize(key) + " is Invalid!";
        }
      }
      return null;
    });

    await setFormErrors(formErrorsObj);
    // alert("ok");

    if (isValid) {
      let data = {
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        email: formValue.email,
        number: formValue.number,
        dob: formValue.dob,
        currentLocation: formValue.currentLocation,
        course: formValue.course,
        institute: formValue.institute,
        yearOfPassing: formValue.yearOfPassing,
        grade: formValue.grade,
        resume: formValue.resume,
      };

      dispatch(CareerEmailAction(data));
      console.log("form dta", data);
    } else {
      console.log("form error");
    }
  };

  const handleReset = () => {
    console.log("possale");
    setFormValues({
      firstName: null,
      lastName: null,
      email: null,
      number: null,
      dob: null,
      currentLocation: null,
      course: null,
      institute: null,
      yearOfPassing: null,
      grade: null,
      resume: null,
    });
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={5} display="flex" flexDirection="row">
        {/* {/* <Grid item lg={12} md={1} sm={2} xs={3}></Grid>
        <Grid item lg={12} md={1} sm={2} xs={3}></Grid> */}
        <Grid item lg={12} md={1} sm={2} xs={3}></Grid>
        <Grid item lg={12} md={1} sm={2} xs={3}></Grid> 
        <Grid item lg={6} md={12} sm={12} xs={2}>
          <Grid container spacing={1} display={"flex"} flexDirection={"row"}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t52_700_L60">Join Hands With Us</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t18_500_L24">
                We love to see you grow with us{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={5}
            display={"flex"}
            justifyContent="flex-end">
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">First Name*</Typography>
              <TextField
                onChange={handleChange}
                name="firstName"
                value={formValue.firstName === null ? "" : formValue.firstName}
                placeholder="first name"
                size="small"
                fullWidth={true}
                required={true}
                error={formErrors.firstName === null ? false : true}
                helperText={
                  formErrors.firstName === null ? "" : formErrors.firstName
                }
              />
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">Last Name*</Typography>
              <TextField
                onChange={handleChange}
                name="lastName"
                value={formValue.lastName === null ? "" : formValue.lastName}
                placeholder="last name"
                size="small"
                fullWidth={true}
                required={true}
                error={formErrors.lastName === null ? false : true}
                helperText={
                  formErrors.lastName === null ? "" : formErrors.lastName
                }
              />
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">Email*</Typography>
              <TextField
                onChange={handleChange}
                name="email"
                value={formValue.email === null ? "" : formValue.email}
                placeholder="email"
                size="small"
                fullWidth={true}
                required={true}
                error={formErrors.email === null ? false : true}
                helperText={formErrors.email === null ? "" : formErrors.email}
              />
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">Contact Number*</Typography>
              <TextField
                onChange={handleChange}
                name="number"
                value={formValue.number === null ? "" : formValue.number}
                placeholder="contact number"
                size="small"
                fullWidth={true}
                required={true}
                error={formErrors.number === null ? false : true}
                helperText={formErrors.number === null ? "" : formErrors.number}
              />
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">Date Of Birth*</Typography>
              <TextField
                name="dob"
                type="date"
                format="yyyy-MM-dd"
                size="small"
                fullWidth={true}
                required={true}
                value={formValue?.dob}
                onChange={(e) =>
                  setFormValues({ ...formValue, dob: e.target.value })
                }
                error={formErrors.dob === null ? false : true}
                helperText={formErrors.dob === null ? "" : formErrors.dob}
              />
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">Current Location*</Typography>
              <TextField
                onChange={handleChange}
                name="currentLocation"
                value={
                  formValue.currentLocation === null
                    ? ""
                    : formValue.currentLocation
                }
                placeholder="Current Location"
                size="small"
                fullWidth={true}
                required={true}
                error={formErrors.currentLocation === null ? false : true}
                helperText={
                  formErrors.currentLocation === null
                    ? ""
                    : formErrors.currentLocation
                }
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography variant="t24_500">Educational Details</Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">Select Course*</Typography>
              <Autocomplete
                options={Course}
                name="course"
                getOptionLabel={(option) =>
                  option["course"] ? option["course"] : option
                }
                onChange={
                  (e, v) =>
                    handleChange({
                      target: {
                        name: "course",
                        value: v === null ? "" : v.course,
                      },
                    })

                  // console.log("vv", v);
                  // handleChange({
                  //   target: {
                  //     name: "course",
                  //     value: v === null ? " " : v.course,
                  //   },
                  // });
                }
                value={formValue?.course}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Cource"
                    size="small"
                    required={true}
                    error={formErrors.course === null ? false : true}
                    helperText={
                      formErrors.course === null ? "" : formErrors.course
                    }
                  />
                )}
              />
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">Institute*</Typography>
              <TextField
                onChange={handleChange}
                name="institute"
                value={formValue.institute === null ? "" : formValue.institute}
                placeholder="Enter Text Here"
                size="small"
                fullWidth={true}
                required={true}
                error={formErrors.institute === null ? false : true}
                helperText={
                  formErrors.institue === null ? "" : formErrors.institute
                }
              />
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">Year Of Passing*</Typography>
              <Autocomplete
                name="yearOfPassing"
                options={year}
                getOptionLabel={(option) =>
                  option["int"] ? option["int"] : option
                }
                onChange={(e, v) =>
                  handleChange({
                    target: {
                      name: "yearOfPassing",
                      value: v === null ? "" : v.int,
                    },
                  })
                }
                // onChange={(e, v) =>
                //   handleChange({
                //     target: {
                //       name: "yearOfPassing",
                //       value: v === null ? "" : v.day,
                //     },
                //   })
                // }
                value={formValue?.yearOfPassing}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Year"
                    size="small"
                    required={true}
                    error={formErrors.yearOfPassing === null ? false : true}
                    helperText={
                      formErrors.yearOfPassing === null
                        ? ""
                        : formErrors.yearOfPassing
                    }
                  />
                )}
              />{" "}
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={9}>
              <Typography variant="t16_500">Grade/Percentage*</Typography>
              <TextField
                onChange={handleChange}
                name="grade"
                value={formValue.grade === null ? "" : formValue.grade}
                placeholder="Enter Text Here"
                size="small"
                fullWidth={true}
                required={true}
                error={formErrors.grade === null ? false : true}
                helperText={formErrors.grade === null ? "" : formErrors.grade}
              />
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={9}>
              <Typography variant="t16_500">Upload Your Resume*</Typography>
              <input
                accept="file/*"
                type="file"
                name="resume"
                onChange={onFileChange}
                id="icon-button-file"
                style={{ display: "none" }}
              />
              <label htmlFor="icon-button-file">
                <Button
                  variant="contained"
                  component="span"
                  //className={classes.button}
                  size="large"
                  fullWidth={true}
                  endIcon={<FileUploadOutlinedIcon color="common.orange" />}
                  sx={{
                    bgcolor: "transparent",
                    border: "1px solid rgba(0, 0, 0, 0.5)",
                    ":hover": { bgcolor: "transparent" },
                  }}>
                  <Typography variant="t14_500" color="grey.600">
                    {fileName ? fileName : "Choose File"}
                  </Typography>
                  <FileUploadOutlinedIcon color="red" />
                </Button>
              </label>
              {/* <TextField
            placeholder="message"
            size="small"
            endIcon={
              <FileUploadOutlinedIcon sx={{ color: "common.orange" }} />
            }
            type="button"
            fullWidth={true}
          /> */}
              {/* <label >
            {/* <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              // required={true}
              // name="resume"
              // fullWidtherror={formErrors.resume === null ? false : true}
              // helperText={formErrors.resume === null ? "" : formErrors.resume}
              onChange={onFileChange}
            /> */}
              {/* <FileUploadButton
              variant="contained"
              component="span"
              onChange={onFileChange}
              endIcon={
                <FileUploadOutlinedIcon sx={{ color: "common.orange" }} />
              }
              accept="image/*"
              //id="contained-button-file"
              multiple
              type="file">
              <Typography variant="t14_500" color="grey.600">
                {fileName ? fileName : "Choose File"}
              </Typography>
            </FileUploadButton>
          // </label> */}
            </Grid>
            <Grid
              item
              lg={10}
              md={10}
              sm={10}
              xs={12}
              display="flex"
              justifyContent="flex-start">
              {/* <Captcha /> */}
              <ReCAPTCHA
                sitekey="6LepiREkAAAAALu7BWPMMN2IsUWHz_9O6dtxQvqd"
                onChange={onChange}
              />
            </Grid>
            <Grid
              item
              lg={10}
              md={10}
              sm={10}
              xs={12}
              display="flex"
              justifyContent="flex-end">
              <Grid
                container
                spacing={3}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end">
                <Grid item display="flex" justifyContent="flex-end">
                  <Button
                    onClick={handleReset}
                    sx={{
                      backgroundColor: "transparent",
                      border: `1.5px solid black`,
                      color: "common.black",
                      width: "100%",
                      height: "100%",
                    }}>
                    <Typography variant="t18_500">{reset_bt}</Typography>
                  </Button>
                </Grid>
                <Grid item display="flex" justifyContent="flex-end">
                  <Button
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: "common.orange",
                      border: `1.5px solid #FD7E3B`,
                      color: "common.white",
                      width: "100%",
                      height: "100%",
                      ":hover": { color: "common.orange" },
                    }}>
                    <Typography variant="t18_500">{submit_bt}</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CareersForm;
