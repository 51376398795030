import { Grid } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'

function NotFound() {
  return (
    <Grid container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VEETEE - About Us</title>
        <link rel="canonical" href="http://localhost:3000/aboutUs" />
        <meta name='title' content={"title"} />
        <meta name='description' content={"description"} />
      </Helmet>
      <div>NotFound</div>
    </Grid>
  )
}

export default NotFound