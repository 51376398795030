import axios from "axios";

let baseURL = "http://localhost:4000" //"https://server.vttwebsite.vtt.im";
let wsUrl = "ws://localhost:4000";

const servers = {
  local: {
    host: "localhost",
    base_url: "http://localhost:4000",
  },
  prod: {

  },
};

// if (process.env.NODE_ENV === "production") {
//   baseURL = "https://vttwebsite.vtt.im";
//   wsUrl = "wss://vttwebsite.vtt.im";
// }

const location = window.location;

export const baseURL1 =
  location.hostname === "vttwebsite.vtt.im"
    ? servers.dev.base_url
    : location.hostname === "vtckl.salesplay.in";

const instance = axios.create({
  baseURL,
  timeout: 100000,
  header: {
    "Content-Type": "application/json",
  },
  responseType: "json",
});

export const base_url = baseURL;
export const ws_url = wsUrl;
export default instance;
