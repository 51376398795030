import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { Grid } from "@mui/material";
import Java from "../../src/asset/ServerSide-1.webp";
import CHash from "../../src/asset/Net-C.webp";
import PHP from "../../src/asset/PHP.webp";
import Python from "../../src/asset/Python.webp";
import Django from "../../src/asset/Django-Python.webp";
import ReactNative from "../../src/asset/ReactNative.webp";
import Ionic from "../../src/asset/Ionic.webp";
import Cordova from "../../src/asset/Cordova.webp";
import PostgreSQl from "../../src/asset/PostgreSQL.webp";
import Oracle from "../../src/asset/Oracle.webp";
import MySQL from "../../src/asset/MySQL.webp";
import SQLServer from "../../src/asset/SQLServer.webp";
import MongoDB from "../../src/asset/MongoDB.webp";
import Cassandra from "../../src/asset/Cassandra.webp";
import BootStrap from "../../src/asset/BootStrap.webp";
import JQuery from "../../src/asset/JQuery.webp";
import AngularJS from "../../src/asset/Angular-JS.webp";
import DreamFactory from "../../src/asset/DreamFactory.webp";
import AWS from "../../src/asset/AWS.webp";
import Azure from "../../src/asset/Azure.webp";
import DigitalOcean from "../../src/asset/DigitalOcean.webp";
import TFS from "../../src/asset/TFS.webp";
import SVN from "../../src/asset/SVN.webp";
import Git from "../../src/asset/Git.webp";
import Jenkins from "../../src/asset/Jenkins.webp";
import Tableau from "../../src/asset/Tableau.webp";
import OBIEE from "../../src/asset/OBIEE.webp";
import SoapUI from "../../src/asset/SoapUI.webp";
import Se from "../../src/asset/Se.webp";
import JMeter from "../../src/asset/J-Meter.webp";
import Cucumber from "../../src/asset/Cucumber.webp";
import Appium from "../../src/asset/Appium.webp";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell></TableCell>
        <TableCell>
          <Typography sx={{color:"grey"}} variant="t22_400"> {row.label}</Typography>
        </TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <RemoveCircleOutlineOutlinedIcon />
          ) : (
            <AddCircleOutlineOutlinedIcon />
          )}
        </IconButton>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" display="flex" flexDirection="row">
            <Grid display={"flex"}>
              {row.content.map((y) => (
                <Box>
                  <img width={"120px"} height={"120px"} src={y}></img>
                </Box>
              ))}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

const rows = [
  { label: "Server Side", content: [Java, CHash, PHP, Python, Django] },
  { label: "Mobile Development", content: [ReactNative, Ionic, Cordova] },
  { label: "Database", content: [PostgreSQl, Oracle, MySQL, SQLServer] },
  { label: "NoSQL Database", content: [MongoDB, Cassandra] },
  { label: "JavaScript Frameworks", content: [BootStrap, JQuery, AngularJS] },
  { label: "API Development", content: [DreamFactory] },
  { label: "Cloud Platforms", content: [AWS, Azure, DigitalOcean] },
  { label: "Source Control", content: [TFS, SVN, Git] },
  { label: "Integration Tool", content: [Jenkins] },
  { label: "Business Intelligence Tool", content: [Tableau, OBIEE] },
  {
    label: "Test Automation Frameworks",
    content: [SoapUI, Se, JMeter, Cucumber, Appium],
  },
];

export default function CollapsibleTable() {
  return (
    <Grid width={"100%"}>
      <TableContainer sx={{ backgroundColor: "white" }}>
        <Table aria-label="collapsible table">
          <TableBody>
            {rows.map((row, index) => (
              <Row key={index} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
