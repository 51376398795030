import { Container, Grid, Typography } from "@mui/material";
import { fontWeight } from "@mui/system";
import React from "react";

export default function CPSTTemp(props) {
  const { clientName, technologyContent, projectContent, servicesContent } =
    props;

  return (
    <Container maxWidth="xl">
      <Grid
        container
        display={"flex"}
        // justifyContent={"center"}
        alignContent={"center"}
        spacing={3}>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={12} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={6} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={6} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={6} md={4} sm={1} xs={1}></Grid>
        <Grid item lg={6} md={4} sm={1} xs={2}></Grid>
        <Grid item lg={6} md={4} sm={1} xs={2}></Grid>
        <Grid item lg={6} md={4} sm={1} xs={2}></Grid>
        <Grid item lg={6} md={4} sm={1} xs={2}></Grid>
        <Grid item lg={6} md={4} sm={1} xs={2}></Grid>
        <Grid
          container
          display={"flex"}
          flexDirection="row"
          spacing={5}
          sx={{
            backgroundColor: "#F8F8F8",
            borderRadius: 4,
            p: "20px",
            // // border: "2px solid black",,
            // height: "50%",
          }}
          // lg={12}
        >
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} pb="10px">
                <Typography variant="t24_700">Client</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="t16_400">{clientName}</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} pt="30px" pb="10px">
                <Typography variant="t24_700">Technology</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="t16_400">{technologyContent}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12} pb="15px">
            <Typography variant="t24_700">Project</Typography>
            {projectContent.map((m) => (
              <>
                <Grid container>
                  <Grid item lg={11} md={12} sm={12} xs={12} pb="20px">
                    <Typography variant="t16_400">
                      {m.projectContent}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} pb="15px">
                <Typography variant="t24_700">Services</Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="t16_400">{servicesContent}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
