//ALERT
export const OPEN_ALERT = "OPEN_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";

export const CONTACT_US_EMAIL = "CONTACT_US_EMAIL";

export const CAREER_EMAIL = "CAREER_EMAIL";

export const PROJECT_EMAIL = "PROJECT_EMAIL";


export const CONTACTUS_WINDOW_SCROLL = "CONTACTUS_WINDOW_SCROLL";
export const SERVICE_WINDOW_SCROLL = "SERVICE_WINDOW_SCROLL";


