import ContactUs from "../pages/contactUs";
import Home from "../pages/home";
import Services from "../pages/services";
import { Routes, Route } from "react-router-dom";
import Skills from "../pages/skills";
import NotFound from "../utils/notFound";
import AboutUs from "../pages/aboutUs";
import Careers from "../pages/careers";
import Projects from "../pages/projects";
import CustomsDutyManagement from "../view/customDutyManagement/index";
import ShippingExecutionManagement from "../view/shippingExecutionManagement";
import SupplyChainDefect from "../view/supplyChainDefect";
import ShippingRequirements from "../view/shippingRequirements";
import OracleDatabaseUpgrade from "../view/oracleDb/index";
import OracleDiscoverer from "../view/oracleDiscoverer/index"

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Home />}
          exact
        />
        <Route
          path="/contactUs"
          element={<ContactUs />}
          exact
        />
        <Route
          path="/service"
          element={<Services />}
          exact
        />
        <Route
          path="/skills"
          element={<Skills />}
          exact
        />
        <Route
          path="/aboutUs"
          element={<AboutUs />}
          exact
        />
        <Route
          path="/notFound"
          element={<NotFound />}
          exact
        />
        <Route
          path="/careers"
          element={<Careers />}
          exact
        />
        <Route
          path="/projects"
          element={<Projects />}
          exact
        />

        {/* --------------------------------------------------------view--------------------------------------------------------- */}

        <Route
          path="/projects/customsDutyManage"
          element={
            <CustomsDutyManagement />
          }
          exact
        />
        <Route
          path="/projects/shippingExecutionManage"
          element={
            <ShippingExecutionManagement />
          }
          exact
        />
        <Route
          path="/projects/supplyChainDefect"
          element={<SupplyChainDefect />}
          exact
        />
        <Route
          path="/projects/shippingRequirements"
          element={<ShippingRequirements />}
          exact
        />
        <Route
          path="/projects/oracleDatabaseUpgrade"
          element={<OracleDatabaseUpgrade />}
          exact
        />
        <Route
          path="/projects/oracleDiscovererToTableauMigration"
          element={<OracleDiscoverer />}
          exact
        />
      </Routes>
    </>
  );
}

export default App;
