import { Grid, Box, Typography, Container } from "@mui/material";
import React from "react";

function Mission() {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={7}>
        {/* <Grid item lg={12} md={12} sm={6} xs={12}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={12}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={2}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={2}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={2}></Grid>
      <Grid item lg={12} md={12} sm={6} xs={2}></Grid>
      <Grid item lg={12} md={12} sm={6} xs={2}></Grid> */}
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Box display="flex" flexDirection="column">
            <Typography variant="t52_700" pb="20px">
              {"Mission"}
            </Typography>
            <Typography variant="t_500">
              {
                "Our qualified, experienced and certified team of IT professionals analyze and facilitate the development of an effective strategic plan that will provide the IT capabilities required to achieve clients’ business goals."
              }
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Box display="flex" flexDirection="column">
            <Typography variant="t52_700" pb="20px">
              {"Our Vision"}
            </Typography>
            <Typography variant="t18_500" pb="20px">
              {
                "We are mindful of our success as a consulting organization which depends on our ability to comprehend the needs of our clients and translate those requirements into improvement actions leading to measurable gains."
              }
            </Typography>
            {/* <Typography variant="t18_500" pb="20px">
            {
              "Product – Always produce applications that squarely fit the purpose."
            }
          </Typography>
          <Typography variant="t18_500">
            {"Process – Follow best practices."}
          </Typography> */}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Mission;
