import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Intro from "../../components/skills/intro";
import SoftwareDev from "../../components/skills/softwareDev";
import SoftwareTesting from "../../components/skills/softwareTesting";
import ItOperatiions from "../../components/skills/itOperations";
import Cards from "../../components/skills/cards";
import Skill from "../../components/skills/skill";
import ResponsiveAppBar from "../../layout/appBar";
import LogoWhite from "../../asset/logoWhite.png";
import ServiceR from "../../components/skills/serviceR";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { contactUsWindowScrollAction } from "../../redux/actions/action";
// import { TabTitle } from "../../utils/commonFunction";

function Skills() {
  const dispatch = useDispatch();

  // TabTitle('VEETEE - Skills')
  let navigate = useNavigate();
  const handleGetInTouch = () => {
    // console.log("Dalapathy");
    navigate("/contactUs");
    // window.scroll(900,900)
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
    dispatch(contactUsWindowScrollAction(650));

  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Grid container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VEETEE - Skills</title>
        <link rel="canonical" href="http://localhost:3000/skills" />
        <meta name='title' content={"title"} />
        <meta name='description' content={"description"} />
      </Helmet>
      <ResponsiveAppBar
        navBarBgColor={"common.blue"}
        ButtonBgcolor={"transparent"}
        logo={LogoWhite}
        ButtonBorder="whitesmoke"
        bText="common.white"
        handleGetInTouch={handleGetInTouch}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        backgroundColor="common.blue"
        maxHeight="50vh">
        <Skill />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Intro />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ServiceR />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <SoftwareDev />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <SoftwareTesting />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ItOperatiions />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Cards />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Skills;
